import { PdfToWord } from "../components/pdfToWord/pdftoword"
import { SideBar } from "../components/sidebar"
import PageMetadata from "../components/pageMetaData"
export const PdfToWordPage =()=>{
    return(<>
    <PageMetadata   title="Image Resize for Free - Freesolutions.in"
        description="description" content="Resize image with advance options. Freesolutions.in website which provides free online tools for images and pdf"
        keywords="Image Resize, freesolutions, online tools" />
        <SideBar/>
<PdfToWord/>
</>
    )
}