import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Button,
  Card,
  CardContent,
  LinearProgress,
  TextField,
  Box,
  IconButton,
} from "@mui/material";
import { FileUpload, ContentCopy, Refresh } from "@mui/icons-material";
import { io } from "socket.io-client";

export const ImageToUrl = () => {
  const [file, setFile] = useState(null);
  const [originalFilename, setOriginalFilename] = useState("");
  const [convertedUrl, setConvertedUrl] = useState("");
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [socket, setSocket] = useState(null);

  useEffect(() => {
    // Connect to Socket.IO server
    const newSocket = io("https://separately-crisp-titmouse.ngrok-free.app"); // Replace with your backend URL
    setSocket(newSocket);

    // Listen for progress updates
    newSocket.on("upload_progress", (data) => {
      setUploadProgress(data.progress);
    });

    // Cleanup on unmount
    return () => newSocket.disconnect();
  }, []);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!file) {
      alert("Please select a file first!");
      return;
    }

    const formData = new FormData();
    formData.append("file", file);

    setIsUploading(true);
    setUploadProgress(0);

    try {
      const response = await fetch("https://separately-crisp-titmouse.ngrok-free.app/api/image-to-url", {
        method: "POST",
        body: formData,
      });

      const data = await response.json();
      setOriginalFilename(data.original_filename);
      setConvertedUrl(data.converted_url);
    } catch (error) {
      console.error("Error uploading file:", error);
    } finally {
      setIsUploading(false);
    }
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(convertedUrl);
    alert("Copied to clipboard!");
  };

  return (
    <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
      <Typography variant="h3" align="center" gutterBottom sx={{ fontWeight: "bold" }}>
        Image to URL Converter
      </Typography>
      <Typography variant="subtitle1" align="center" gutterBottom sx={{ color: "text.secondary" }}>
        Embed small images directly in your HTML or CSS by converting the image to a Data URL to
        improve performance by reducing the number of HTTP requests needed to load a webpage.
      </Typography>

      <Card sx={{ mt: 4 }}>
        <CardContent>
          <form onSubmit={handleSubmit}>
            <Typography variant="h6" gutterBottom>
              Choose Image
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
              <TextField
                type="file"
                onChange={handleFileChange}
                fullWidth
                variant="outlined"
                InputProps={{
                  startAdornment: <FileUpload />,
                }}
              />
              <Button type="submit" variant="contained" color="primary" disabled={isUploading}>
                Upload
              </Button>
            </Box>
          </form>

          {isUploading && (
            <Box sx={{ mt: 2 }}>
              <LinearProgress variant="determinate" value={uploadProgress} />
              <Typography variant="body2" align="center" sx={{ mt: 1 }}>
                Uploading: {uploadProgress}%
              </Typography>
            </Box>
          )}
        </CardContent>
      </Card>

      {convertedUrl && (
        <Box sx={{ mt: 4, textAlign: "center" }}>
          <Typography variant="h4" gutterBottom>
            Conversion Result
          </Typography>
          <Typography variant="body1" gutterBottom>
            Original Image: {originalFilename}
          </Typography>
          <Box
            component="img"
            src={convertedUrl}
            alt="Converted"
            sx={{ maxWidth: "100%", maxHeight: "150px", borderRadius: 2, boxShadow: 3 }}
          />

          <Box sx={{ mt: 2 }}>
            <Typography variant="body1" gutterBottom>
              Converted Image URL:
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <TextField
                value={convertedUrl}
                fullWidth
                variant="outlined"
                InputProps={{
                  readOnly: true,
                }}
              />
              <IconButton onClick={copyToClipboard} color="primary">
                <ContentCopy />
              </IconButton>
            </Box>
          </Box>

          <Box sx={{ mt: 2 }}>
            <Button
              variant="contained"
              color="primary"
              onClick={copyToClipboard}
              startIcon={<ContentCopy />}
              sx={{ mr: 2 }}
            >
              Copy URL
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => window.location.reload()}
              startIcon={<Refresh />}
            >
              Back
            </Button>
          </Box>
        </Box>
      )}
    </Container>
  );
};