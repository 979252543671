
import { ImageToUrl } from "../components/imageToUrl/imagetourl"
import { ImageToUrlContent } from "../components/imageToUrl/imagetourlContent"
import { SideBar } from "../components/sidebar"
import '../css/layoutcss.css'
import PageMetadata from "../components/pageMetaData"
export const ImageToUrlPage=()=>{
    return(

        <>
        <PageMetadata   title="Image to Url Converter - Freesolutions.in"
        description="description" content="Image to url converter is a free tool by freesolutions.in where you can convert photo into url and use it any where to embed in website or store as base64 data. converting img to url made easy and free."
        keywords="image to url converter, img to url,converting img to url, image to url, convert image to data, data url, image file, image to data, turn an image, image to data url, image to data uri" />

       
<SideBar/>
        <ImageToUrl/>
        <ImageToUrlContent/>
        </>
    )
}