import ImageToText from "../components/imageToText/imageToText";
import PageMetadata from "../components/pageMetaData";
import { SideBar } from "../components/sidebar";

export const ImageToTextPage=()=>{
    return(<>
    <PageMetadata   title="Image To Text Converter For Free - Freesolutions.in"
        description="description" content="Image to Text converter with advance options. Freesolutions.in website which provides free online tools for images and pdf"
        keywords="Image Resize, freesolutions, online tools" />
        <SideBar/>
    <ImageToText/>
    </>)
}