import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage"; 
import { useAuthState } from "react-firebase-hooks/auth";
const firebaseConfig = {
    apiKey: "AIzaSyC6RV_-LdpFYJBL8UIt2WF23hoeV3oLICE",
    authDomain: "freesolutions-in.firebaseapp.com",
    projectId: "freesolutions-in",
    storageBucket: "freesolutions-in.firebasestorage.app",
    messagingSenderId: "958083196326",
    appId: "1:958083196326:web:1824e6ba6a1903693c5b67",
    measurementId: "G-HDTSSS9DKK"



};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const googleProvider = new GoogleAuthProvider();
const storage = getStorage(app); 

 

export { auth, db, googleProvider, storage };