import PrivacyPolicy from "../../components/pageComponents/privacyPolicy"
import { SideBar } from "../../components/sidebar"

import PageMetadata from "../../components/pageMetaData"
export const PrivacyPolicyPage =()=>{
    return(<>
    <PageMetadata   title="Privacy Policy - Freesolutions.in"
        description="description" content="Privacy Policy for freesolutions.in website which provides free online tools for images and pdf"
        keywords="privacy policy,freesolutions,online tools" />
    <SideBar/>
    <PrivacyPolicy/>
    
    </>)
}