import { SideBar } from "../components/sidebar";
import SitemapGenerator from "../components/sitemapGenerator/sitemapGenerator";

export const SitemapGeneratorPage=()=>{


    return(<>
    <SideBar/>
    <SitemapGenerator/>
    </>)
}