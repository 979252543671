import { Header } from "../components/header"
import { Footer } from "../components/footer"
import { AllTools } from "../components/homeComponents/allTools"
import { SideBar } from "../components/sidebar"
import "../css/layoutcss.css"
import{ Box, Paper } from "@mui/material"
import { Typography } from "@mui/material"
import { useUser } from "../context/userContext"
export const Home =()=>{

  const user = useUser()


    return (
        <>
      
      <SideBar/>
     
        <AllTools/>
      
       
        </>
    )
}