import React, { useEffect, useState } from "react";
import { db, auth, storage } from "../../firebase/firebase";
import { doc, getDoc, updateDoc, collection, query, where, getDocs } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { updateProfile } from "firebase/auth";
import { Container, TextField, Button, Avatar, Typography, Box, CircularProgress } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { useAuthState } from "react-firebase-hooks/auth";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useUser } from "../../context/userContext";
import { Fullscreen } from "@mui/icons-material";

const UserProfile = ({currentUser}) => {
  //  const [user, userLoading] = useAuthState(auth);

    const { user } = useUser();

  const [userData, setUserData] = useState({
    name: "",
    email: "",
    phone: "",
    photoURL: "",
    blogCount: 0,
  });
  const [loading, setLoading] = useState(true);
  const [updating, setUpdating] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);

  //const user = auth.currentUser;
  


  useEffect(() => {
    const fetchUserData = async () => {
        console.log(user)
 
        
      if (!user) return;
      try {
     
        const userDoc = await getDoc(doc(db, "users", user.uid));
        const userBlogsQuery = query(collection(db, "blogs"), where("author", "==", user.uid));
        const userBlogsSnapshot = await getDocs(userBlogsQuery);
        
        
        if (userDoc.exists()) {
          setUserData({
            name: userDoc.data().name || user.displayName || "",
            email: user.email,
            phone: userDoc.data().phone || "",
            photoURL: userDoc.data().photoURL || user.photoURL || "",
            blogCount: userBlogsSnapshot.size,
            
          });
           setLoading(false);

        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchUserData();
  }, [user]);

  const handleUpdate = async () => {
    if (!user) return;
    setUpdating(true);
    try {
      const userRef = doc(db, "users", user.uid);
      await updateDoc(userRef, {
        name: userData.name,
        phone: userData.phone,
        photoURL: userData.photoURL,
      });
      await updateProfile(user, { displayName: userData.name, photoURL: userData.photoURL });
    } catch (error) {
      console.error("Error updating profile:", error);
    } finally {
      setUpdating(false);
    }
  };

  const handleImageUpload = async (e) => {
    const file = e.target.files[0];
    if (!file) return;
    
    setSelectedFile(file);
    const storageRef = ref(storage, `profile_pictures/${user.uid}`);
    setUpdating(true);
    try {
      await uploadBytes(storageRef, file);
      const downloadURL = await getDownloadURL(storageRef);
      setUserData({ ...userData, photoURL: downloadURL });
    } catch (error) {
      console.error("Error uploading image:", error);
    } finally {
      setUpdating(false);
    }
  };

  if (loading) {
    return <Box style={{height:"500px"}}> <div style={{minHeight:"5000"}}/>
    <CircularProgress sx={{ display: "block", margin: "auto", mt: 4 }} />
    </Box>;
  }

  return (
    <Container maxWidth="sm" sx={{ mt: 4 }}>
      <Box display="flex" flexDirection="column" alignItems="center" gap={2}>
        <Avatar src={userData.photoURL} sx={{ width: 100, height: 100 }} />
        <input accept="image/*" type="file" style={{ display: "none" }} id="upload-photo" onChange={handleImageUpload} />
        <label htmlFor="upload-photo">
          <Button component="span" variant="outlined" startIcon={<CloudUploadIcon />}>
            Upload Photo
          </Button>
        </label>

        <Typography variant="h5">Edit Profile</Typography>

        <TextField label="Name" fullWidth value={userData.name} onChange={(e) => setUserData({ ...userData, name: e.target.value })} />
        <TextField label="Email" fullWidth value={userData.email} disabled />
        <TextField label="Phone Number" fullWidth value={userData.phone} onChange={(e) => setUserData({ ...userData, phone: e.target.value })} />

        <Button variant="contained" color="primary" onClick={handleUpdate} disabled={updating}>
          {updating ? <CircularProgress size={24} /> : "Save Changes"}
        </Button>

        <Typography variant="subtitle1" sx={{ mt: 2 }}>
          Total Blogs Published: <strong>{userData.blogCount}</strong>
        </Typography>
      </Box>
    </Container>
  );
};

export default UserProfile;
