import YoutubeEmbedGenerator from "../components/youtubeEmbedCode/youtubeEmbedCode";
import PageMetadata from "../components/pageMetaData";
import { SideBar } from "../components/sidebar";
export const YoutubeEmbedCodePage=()=>{
    return(<>
     <PageMetadata   title="Youtube Embed Code Generator - Freesolutions.in"
            description="description" content="Generate youtube video embed code with advance options for free. Freesolutions.in website which provides free online tools for images, pdf, etc"
            keywords="Image Resize, freesolutions, online tools" />
            <SideBar/>
    <YoutubeEmbedGenerator/>
    </>)
}