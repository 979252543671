import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Container,
  Typography,
  Box,
  Button,
  Paper,
  CircularProgress,
  TextField,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import CasinoIcon from '@mui/icons-material/Casino';
import EmojiEmotionsIcon from '@mui/icons-material/EmojiEmotions';
import MoodBadIcon from '@mui/icons-material/MoodBad';
import SentimentNeutralIcon from '@mui/icons-material/SentimentNeutral';

const LuckGame = () => {
  const [name, setName] = useState('');
  const [result, setResult] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [luckyNames, setLuckyNames] = useState([]);

  // Fetch lucky names when the component mounts
  useEffect(() => {
    fetchLuckyNames();
  }, []);

  const fetchLuckyNames = async () => {
    try {
      const response = await axios.get('https://separately-crisp-titmouse.ngrok-free.app/api/get-lucky-names');
      setLuckyNames(response.data.lucky_names);
      console.log(luckyNames)
      console.log(response.data.lucky_names)
    } catch (error) {
      console.error('Error fetching lucky names:', error);
    }
  };

  const checkLuck = async () => {
    if (!name) {
      alert('Please enter your name before checking your luck.');
      return;
    }

    setIsLoading(true);
    setResult(null);

    // Simulate a delay for the "luck check"
    setTimeout(async () => {
      const luckOptions = ['Lucky', 'Unlucky', 'Neutral'];
      const randomLuck = luckOptions[Math.floor(Math.random() * luckOptions.length)];
      setResult(randomLuck);

      // If the user is lucky, add their name to the backend
      if (randomLuck === 'Lucky') {
        try {
          await axios.post('https://separately-crisp-titmouse.ngrok-free.app/api/add-lucky-name', { name });
          fetchLuckyNames(); // Refresh the lucky names list
        } catch (error) {
          console.error('Error adding lucky name:', error);
        }
      }

      setIsLoading(false);
    }, 1500); // 1.5 seconds delay
  };

  const getResultIcon = () => {
    switch (result) {
      case 'Lucky':
        return <EmojiEmotionsIcon sx={{ fontSize: 80, color: 'green' }} />;
      case 'Unlucky':
        return <MoodBadIcon sx={{ fontSize: 80, color: 'red' }} />;
      case 'Neutral':
        return <SentimentNeutralIcon sx={{ fontSize: 80, color: 'orange' }} />;
      default:
        return null;
    }
  };

  const getResultMessage = () => {
    switch (result) {
      case 'Lucky':
        return `Congratulations, ${name}! You are Lucky today! 🎉`;
      case 'Unlucky':
        return `Oops, ${name}! Better luck next time! 😢`;
      case 'Neutral':
        return `Hmm, ${name}... Today is a neutral day. 🤔`;
      default:
        return '';
    }
  };

  return (
    <Container maxWidth="md">
      <Box sx={{ mt: 4, textAlign: 'center' }}>
        <Typography variant="h3" gutterBottom>
          Check Your Luck
        </Typography>
        <Paper elevation={3} sx={{ p: 4, mt: 2 }}>
          <CasinoIcon sx={{ fontSize: 80, color: 'primary.main' }} />
          <TextField
            label="Enter Your Name"
            fullWidth
            value={name}
            onChange={(e) => setName(e.target.value)}
            sx={{ mt: 2 }}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={checkLuck}
            disabled={isLoading || !name}
            fullWidth
            sx={{ mt: 2 }}
          >
            {isLoading ? <CircularProgress size={24} /> : 'Check Your Luck'}
          </Button>
          {result && (
            <Box sx={{ mt: 4 }}>
              {getResultIcon()}
              <Typography variant="h5" sx={{ mt: 2 }}>
                {getResultMessage()}
              </Typography>
            </Box>
          )}
        </Paper>

        {/* Display Lucky Names */}
        {luckyNames?.length > 0 && (
          <Box sx={{ mt: 4 }}>
            <Typography variant="h5" gutterBottom>
              Lucky Winners 🏆
            </Typography>
            <List>
              {luckyNames.map((name, index) => (
                <ListItem key={index}>
                  <ListItemText primary={`${index + 1}. ${name}`} />
                </ListItem>
              ))}
            </List>
          </Box>
        )}
      </Box>
    </Container>
  );
};

export default LuckGame;