import React, { useState } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  Container,
  Typography,
  Box,
  TextField,
  Button,
  Paper,
  FormControlLabel,
  Switch,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from '@mui/material';

const YoutubeEmbedGenerator = () => {
  const [videoUrl, setVideoUrl] = useState('');
  const [width, setWidth] = useState(560);
  const [height, setHeight] = useState(315);
  const [loadAsyncPlayer, setLoadAsyncPlayer] = useState(false);
  const [youtubeEmbedThumbnail, setYoutubeEmbedThumbnail] = useState(false);
  const [asyncPlayerOnly, setAsyncPlayerOnly] = useState(false);
  const [imageUrl, setImageUrl] = useState('');
  const [responsivePlayer, setResponsivePlayer] = useState(false);
  const [playerTechnology, setPlayerTechnology] = useState('HTML5');
  const [playerTheme, setPlayerTheme] = useState('Dark');
  const [progressBarColor, setProgressBarColor] = useState('Red');
  const [autoplay, setAutoplay] = useState(false);
  const [disableKeyboard, setDisableKeyboard] = useState(false);
  const [hideControls, setHideControls] = useState(false);
  const [showCaptions, setShowCaptions] = useState(false);
  const [hideLogo, setHideLogo] = useState(false);
  const [hideFullscreenButton, setHideFullscreenButton] = useState(false);
  const [showBorder, setShowBorder] = useState(false);
  const [hideTopBar, setHideTopBar] = useState(false);
  const [hideRelatedVideos, setHideRelatedVideos] = useState(false);
  const [createPlaylist, setCreatePlaylist] = useState(false);
  const [playlistIds, setPlaylistIds] = useState('');
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [showAnnotations, setShowAnnotations] = useState(false);
  const [embedCode, setEmbedCode] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleGenerate = async () => {
    if (!videoUrl) {
      toast.error('Please enter a YouTube video URL.');
      return;
    }

    setIsLoading(true);

    try {
      const response = await axios.post('https://separately-crisp-titmouse.ngrok-free.app/api/youtube-embed/generate', {
        video_url: videoUrl,
        width: width,
        height: height,
        load_async_player: loadAsyncPlayer,
        youtube_embed_thumbnail: youtubeEmbedThumbnail,
        async_player_only: asyncPlayerOnly,
        image_url: imageUrl,
        responsive_player: responsivePlayer,
        player_technology: playerTechnology,
        player_theme: playerTheme,
        progress_bar_color: progressBarColor,
        autoplay: autoplay,
        disable_keyboard: disableKeyboard,
        hide_controls: hideControls,
        show_captions: showCaptions,
        hide_logo: hideLogo,
        hide_fullscreen_button: hideFullscreenButton,
        show_border: showBorder,
        hide_top_bar: hideTopBar,
        hide_related_videos: hideRelatedVideos,
        create_playlist: createPlaylist,
        playlist_ids: playlistIds,
        start_time: startTime,
        end_time: endTime,
        show_annotations: showAnnotations,
      });

      setEmbedCode(response.data.embed_code);
      toast.success('Embed code generated successfully!');
    } catch (error) {
      console.error('Error generating embed code:', error);
      toast.error('Failed to generate embed code.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container maxWidth="md">
      <Box sx={{ mt: 4, textAlign: 'center' }}>
        <Typography variant="h3" gutterBottom>
          Advanced YouTube Embed Code Generator
        </Typography>
        <Paper elevation={3} sx={{ p: 4, mt: 2 }}>
          <TextField
            label="YouTube Video URL"
            fullWidth
            value={videoUrl}
            onChange={(e) => setVideoUrl(e.target.value)}
            placeholder="https://www.youtube.com/watch?v=..."
            sx={{ mb: 2 }}
          />
          <TextField
            label="Width (px)"
            type="number"
            fullWidth
            value={width}
            onChange={(e) => setWidth(e.target.value)}
            sx={{ mb: 2 }}
          />
          <TextField
            label="Height (px)"
            type="number"
            fullWidth
            value={height}
            onChange={(e) => setHeight(e.target.value)}
            sx={{ mb: 2 }}
          />
          <FormControlLabel
            control={
              <Switch
                checked={loadAsyncPlayer}
                onChange={(e) => setLoadAsyncPlayer(e.target.checked)}
                color="primary"
              />
            }
            label="Load Async Player"
            sx={{ mb: 1 }}
          />
          <FormControlLabel
            control={
              <Switch
                checked={youtubeEmbedThumbnail}
                onChange={(e) => setYoutubeEmbedThumbnail(e.target.checked)}
                color="primary"
              />
            }
            label="YouTube Embed Thumbnail"
            sx={{ mb: 1 }}
          />
          <FormControlLabel
            control={
              <Switch
                checked={asyncPlayerOnly}
                onChange={(e) => setAsyncPlayerOnly(e.target.checked)}
                color="primary"
              />
            }
            label="Async Player Only"
            sx={{ mb: 1 }}
          />
          <TextField
            label="Image URL"
            fullWidth
            value={imageUrl}
            onChange={(e) => setImageUrl(e.target.value)}
            sx={{ mb: 2 }}
          />
          <FormControlLabel
            control={
              <Switch
                checked={responsivePlayer}
                onChange={(e) => setResponsivePlayer(e.target.checked)}
                color="primary"
              />
            }
            label="Responsive Video Player"
            sx={{ mb: 1 }}
          />
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel>Player Technology</InputLabel>
            <Select
              value={playerTechnology}
              onChange={(e) => setPlayerTechnology(e.target.value)}
              label="Player Technology"
            >
              <MenuItem value="HTML5">HTML5</MenuItem>
              <MenuItem value="Flash">Flash</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel>Player Theme</InputLabel>
            <Select
              value={playerTheme}
              onChange={(e) => setPlayerTheme(e.target.value)}
              label="Player Theme"
            >
              <MenuItem value="Dark">Dark</MenuItem>
              <MenuItem value="Light">Light</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel>Progress Bar Color</InputLabel>
            <Select
              value={progressBarColor}
              onChange={(e) => setProgressBarColor(e.target.value)}
              label="Progress Bar Color"
            >
              <MenuItem value="Red">Red</MenuItem>
              <MenuItem value="Blue">Blue</MenuItem>
              <MenuItem value="Green">Green</MenuItem>
            </Select>
          </FormControl>
          <FormControlLabel
            control={
              <Switch
                checked={autoplay}
                onChange={(e) => setAutoplay(e.target.checked)}
                color="primary"
              />
            }
            label="YouTube Autoplay"
            sx={{ mb: 1 }}
          />
          <FormControlLabel
            control={
              <Switch
                checked={disableKeyboard}
                onChange={(e) => setDisableKeyboard(e.target.checked)}
                color="primary"
              />
            }
            label="Disable Keyboard"
            sx={{ mb: 1 }}
          />
          <FormControlLabel
            control={
              <Switch
                checked={hideControls}
                onChange={(e) => setHideControls(e.target.checked)}
                color="primary"
              />
            }
            label="Hide YouTube Controls"
            sx={{ mb: 1 }}
          />
          <FormControlLabel
            control={
              <Switch
                checked={showCaptions}
                onChange={(e) => setShowCaptions(e.target.checked)}
                color="primary"
              />
            }
            label="Show YouTube Captions"
            sx={{ mb: 1 }}
          />
          <FormControlLabel
            control={
              <Switch
                checked={hideLogo}
                onChange={(e) => setHideLogo(e.target.checked)}
                color="primary"
              />
            }
            label="Hide YouTube Logo"
            sx={{ mb: 1 }}
          />
          <FormControlLabel
            control={
              <Switch
                checked={hideFullscreenButton}
                onChange={(e) => setHideFullscreenButton(e.target.checked)}
                color="primary"
              />
            }
            label="Hide Fullscreen Button"
            sx={{ mb: 1 }}
          />
          <FormControlLabel
            control={
              <Switch
                checked={showBorder}
                onChange={(e) => setShowBorder(e.target.checked)}
                color="primary"
              />
            }
            label="Show Border"
            sx={{ mb: 1 }}
          />
          <FormControlLabel
            control={
              <Switch
                checked={hideTopBar}
                onChange={(e) => setHideTopBar(e.target.checked)}
                color="primary"
              />
            }
            label="Hide Top Bar"
            sx={{ mb: 1 }}
          />
          <FormControlLabel
            control={
              <Switch
                checked={hideRelatedVideos}
                onChange={(e) => setHideRelatedVideos(e.target.checked)}
                color="primary"
              />
            }
            label="Hide Related Video at End"
            sx={{ mb: 1 }}
          />
          <FormControlLabel
            control={
              <Switch
                checked={createPlaylist}
                onChange={(e) => setCreatePlaylist(e.target.checked)}
                color="primary"
              />
            }
            label="Create YouTube Playlist"
            sx={{ mb: 1 }}
          />
          <TextField
            label="Playlist Video IDs (comma-separated)"
            fullWidth
            value={playlistIds}
            onChange={(e) => setPlaylistIds(e.target.value)}
            sx={{ mb: 2 }}
          />
          <TextField
            label="Start Time (hh:mm:ss)"
            fullWidth
            value={startTime}
            onChange={(e) => setStartTime(e.target.value)}
            sx={{ mb: 2 }}
          />
          <TextField
            label="End Time (hh:mm:ss)"
            fullWidth
            value={endTime}
            onChange={(e) => setEndTime(e.target.value)}
            sx={{ mb: 2 }}
          />
          <FormControlLabel
            control={
              <Switch
                checked={showAnnotations}
                onChange={(e) => setShowAnnotations(e.target.checked)}
                color="primary"
              />
            }
            label="Show YouTube Annotations"
            sx={{ mb: 2 }}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleGenerate}
            disabled={isLoading}
            fullWidth
          >
            {isLoading ? 'Generating...' : 'Generate Embed Code'}
          </Button>
          {embedCode && (
            <Box sx={{ mt: 2 }}>
              <Typography variant="h6" gutterBottom>
                Embed Code:
              </Typography>
              <TextField
                value={embedCode}
                fullWidth
                multiline
                rows={4}
                variant="outlined"
                sx={{ mt: 1 }}
              />
            </Box>
          )}
        </Paper>
      </Box>
      <ToastContainer />
    </Container>
  );
};

export default YoutubeEmbedGenerator;