export const Footer =()=>{

    return (
        <>
                <section class="contact_wrapper mt-3 mt-lg-0" id="contact">
            <div class="container-fluid">
                <div class="row align-items-center justify-content-center">
                    <div class="col-lg-4 col-md-12 mb-3 mb-lg-0">
                        <div class="copyright-text2 text-center text-lg-center mb-3">
                           <a href="/terms-and-conditions">Terms and Conditions | </a>
                           <a href="/privacy-policy">Privacy Policy | </a>
                           <a href="/blogs">Blog | </a>
                           <a href="/contact">Contact Us</a>
                        </div>
                    </div>
                </div>
                <div class="row align-items-center justify-content-center">
                    <div class="col-lg-4 col-md-12">
                        <div class="copyright-text text-center text-lg-center">
                            <p class="mb-0"> © FreeSolutions.In 2023 <a href="#">All Rights Reserved.</a></p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        </>
    )
}