import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Home } from './pages/home';
import { ImageToUrl } from './components/imageToUrl/imagetourl';
import { Header } from './components/header';
import { Footer} from './components/footer.js'
  import { SideBar } from ".//components/sidebar.js"

  import { PdfToWordPage } from './pages/pdftowordPage.js';

import { ImageToUrlPage } from './pages/imagetourlPage.js';
import { ImageResizePage } from './pages/imageResizePage.js';
import { PdfMergerPage } from './pages/pdfMergerPage.js';
import { ImageToPdfPage } from './pages/imageToPdfPage.js';
import { SitemapGeneratorPage } from './pages/siteMapGeneratorPage.js';
import { AgeCalculatorPage } from './pages/ageCalculatorPage.js';
import { YoutubeEmbedCodePage } from './pages/youtubeEmbedCodePage.js';
import { IframeGeneratorPage } from './pages/iframegeneratorPage.js';
import { ImageToTextPage } from './pages/imageToTextPage.js';
import { PasswordGeneratorPage } from './pages/passwordGeneratorPage.js';
import { CheckYourLuck } from './pages/checkYourLuck.js';

import TermsAndConditionsPage from './pages/page/termsandconditions.js';
import { PrivacyPolicyPage } from './pages/page/pravicypolicyPage.js';
import { ConstactUsPagePage } from './pages/page/contactUsPage.js';
import CreateBlog from './components/blogComponents/createBlog.js';

import Login from "./components/login";
import Signup from "./components/signup";

import AdminDashboard from "./components/admineDashboard";
import Logout from "./components/logout";
import UserDashboard from './components/userDashboard.js';
import BlogPage from './components/blogComponents/blogPage.js';
import BlogDetail from './components/blogComponents/blogDetail.js';
import { ProfilePage } from './pages/profilePage.js';
import { auth } from './firebase/firebase.js';
import { useAuthState } from 'react-firebase-hooks/auth';
import { UserProfilePage } from './pages/blogPages/userProfilePage.js';
import { BlogDetailsPage } from './pages/blogPages/blogDetailsPage.js';
import { UserDashboardPage } from './pages/blogPages/userDashboardPage.js';
import { BlogListPage } from './pages/blogPages/blogsListPage.js';
import { AdminDashboardPage } from './pages/blogPages/adminDashboardPage.js';
import { CreateBlogPage } from './pages/blogPages/createBlogPage.js';

import { useUser } from './context/userContext.js';

import { Box, Paper, Typography } from '@mui/material';
//import AllPageMessage from './components/message/allPageMessage.js';
import HorizontalPromoBar from './components/message/allPageMessage.js';

function App(){
  const currentUser = useAuthState(auth)
  console.log(currentUser.uid)
//const user = useUser()
const [user, userLoading] = useAuthState(auth);

  
  return (
    <>
        <Box >
        
                      {!user?.uid && 
                    <HorizontalPromoBar/>
                      
                      }
                      
              
                    </Box>
    <Router>
            <Routes>

           
             
                <Route path="/" element={<Home />} />

                <Route path="/image-to-url-convert" element={<ImageToUrlPage />} />
                <Route path="/pdf-to-word-converter" element={<PdfToWordPage />} />
                <Route path="/image-resize" element={<ImageResizePage />} />
                <Route path="/image-to-pdf" element={<ImageToPdfPage />} />
                <Route path="/sitemap-generator" element={<SitemapGeneratorPage />} />
                <Route path="/pdf-merge-online-free" element={<PdfMergerPage />} />
                <Route path="/age-calculator" element={<AgeCalculatorPage />} />
                <Route path="/youtube-embed-code-generator" element={<YoutubeEmbedCodePage />} />
                <Route path="/iframe-generator-free" element={<IframeGeneratorPage />} />
                <Route path="/image-to-text-converter" element={<ImageToTextPage />} />
                <Route path="/strong-password-generator" element={<PasswordGeneratorPage/>} />
                <Route path="/lucky-people" element={<CheckYourLuck/>} />


                <Route path="/terms-and-conditions" element={<TermsAndConditionsPage/>} />
                <Route path="/privacy-policy" element={<PrivacyPolicyPage/>} />
                <Route path="/contact" element={<ConstactUsPagePage/>} />

                <Route path="/createblog" element={<CreateBlogPage/>} />

                <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
       
        
        <Route path="/logout" element={<Logout />} />
        <Route path="/admin-dashboard" element={<AdminDashboardPage/>} />
        <Route path="/blogs" element={<BlogListPage/>} />
        <Route path="/user-dashboard" element={<UserDashboardPage/>} />

        <Route path="/blog/:slug" element={<BlogDetailsPage/>} />
        <Route path="/profile" element={<UserProfilePage/>} />


           
                
                 
                <Route path="*" element={<Home/>} /> 
             
            </Routes>
        </Router>
   
   </>
  );
}

export default App;
