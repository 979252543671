import React, { useState } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  Container,
  Typography,
  Box,
  Button,
  CircularProgress,
  Paper,
  List,
  ListItem,
  ListItemText,
  IconButton,
  LinearProgress,
} from '@mui/material';
import { useDrag, useDrop, DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import DeleteIcon from '@mui/icons-material/Delete';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';


// Draggable PDF Item Component
const PdfItem = ({ file, index, moveFile, handleDelete }) => {
  const [, ref] = useDrag({
    type: 'PDF',
    item: { index },
  });

  const [, drop] = useDrop({
    accept: 'PDF',
    hover: (draggedItem) => {
      if (draggedItem.index !== index) {
        moveFile(draggedItem.index, index);
        draggedItem.index = index;
      }
    },
  });

  return (
    <ListItem
      ref={(node) => ref(drop(node))}
      secondaryAction={
        <IconButton edge="end" aria-label="delete" onClick={() => handleDelete(index)}>
        <DeleteIcon/>
        </IconButton>
      }
      sx={{ backgroundColor: 'background.paper', mb: 1, borderRadius: 1 }}
    >
      <ListItemText primary={file.name} />
    </ListItem>
  );
};

const PdfMerger = () => {
  const [files, setFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [downloadLink, setDownloadLink] = useState('');

  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    setFiles((prevFiles) => [...prevFiles, ...selectedFiles]);
  };

  const handleDelete = (index) => {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const moveFile = (fromIndex, toIndex) => {
    const updatedFiles = [...files];
    const [movedFile] = updatedFiles.splice(fromIndex, 1);
    updatedFiles.splice(toIndex, 0, movedFile);
    setFiles(updatedFiles);
  };

  const handleMerge = async () => {
    if (files.length < 2) {
      toast.error('Please upload at least 2 PDFs to merge.');
      return;
    }

    const formData = new FormData();
    files.forEach((file) => formData.append('files', file));

    setIsLoading(true);
    setUploadProgress(0);

    try {
      const response = await axios.post('https://separately-crisp-titmouse.ngrok-free.app/api/pdf/merge', formData, {
        responseType: 'blob',
        onUploadProgress: (progressEvent) => {
          const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
          setUploadProgress(progress);
        },
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      setDownloadLink(url);
      toast.success('PDFs merged successfully!');
    } catch (error) {
      console.error('Error merging PDFs:', error);
      toast.error('Failed to merge PDFs.');
    } finally {
      setIsLoading(false);
      setUploadProgress(0);
    }
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <Container maxWidth="md">
        <Box sx={{ mt: 4, textAlign: 'center' }}>
          <Typography variant="h3" gutterBottom>
            PDF Merger
          </Typography>
          <Paper elevation={3} sx={{ p: 4, mt: 2 }}>
            <input
              type="file"
              accept="application/pdf"
              onChange={handleFileChange}
              style={{ display: 'none' }}
              id="upload-button"
              multiple
            />
            <label htmlFor="upload-button">
              <Button
                variant="contained"
                component="span"
                startIcon={<CloudUploadIcon/>}
                fullWidth
              >
                Upload PDFs
              </Button>
            </label>
            {files.length > 0 && (
              <>
                <List sx={{ mt: 2 }}>
                  {files.map((file, index) => (
                    <PdfItem
                      key={index}
                      file={file}
                      index={index}
                      moveFile={moveFile}
                      handleDelete={handleDelete}
                    />
                  ))}
                </List>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleMerge}
                  disabled={isLoading || files.length < 2}
                  fullWidth
                  sx={{ mt: 2 }}
                >
                  {isLoading ? <CircularProgress size={24} /> : 'Merge PDFs'}
                </Button>
                {isLoading && (
                  <Box sx={{ mt: 2 }}>
                    <LinearProgress variant="determinate" value={uploadProgress} />
                    <Typography variant="body2" sx={{ mt: 1 }}>
                      Uploading: {uploadProgress}%
                    </Typography>
                  </Box>
                )}
                {downloadLink && (
                  <Button
                    variant="contained"
                    color="success"
                    fullWidth
                    href={downloadLink}
                    download="merged.pdf"
                    sx={{ mt: 2 }}
                  >
                    Download Merged PDF
                  </Button>
                )}
              </>
            )}
          </Paper>
        </Box>
        <ToastContainer />
      </Container>
    </DndProvider>
  );
};

export default PdfMerger;