import LuckGame from "../components/checkYourLuck/checkYourLuck";
import PageMetadata from "../components/pageMetaData";
export const CheckYourLuck=()=>{
    return(
        <>
         <PageMetadata   title="Check Your Luck - Freesolutions.in"
        description="description" content="Check your luck today. Freesolutions.in website which provides free online tools for images and pdf"
        keywords="Check Your Luck, luck game, online tools" />
        <LuckGame/>
        </>
    )
}