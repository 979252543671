import React, { useState, useRef } from "react";
import { db, storage } from "../../firebase/firebase";
import { collection, addDoc, query, where, getDocs, doc, getDoc } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../firebase/firebase";
import { 
  Container, 
  TextField, 
  Button, 
  Typography, 
  Alert, 
  Box, 
  CircularProgress,
  Card,
  CardContent,
  CardHeader,
  Avatar,
  Chip,
  Divider,
  IconButton,
  Tooltip,
  Paper,
  Grid,
  FormControl,
  FormLabel,
  FormHelperText
} from "@mui/material";
import { 
  CloudUpload as CloudUploadIcon,
  Image as ImageIcon,
  Info as InfoIcon,
  CheckCircle as CheckCircleIcon
} from "@mui/icons-material";
import JoditEditor from "jodit-react";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  card: {
    borderRadius: 12,
    boxShadow: "0 8px 16px rgba(0,0,0,0.1)",
  },
  header: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  avatar: {
    backgroundColor: theme.palette.secondary.main,
  },
  editorContainer: {
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: 4,
    marginBottom: theme.spacing(2),
    "& .jodit-container": {
      borderRadius: "0 0 4px 4px",
    },
  },
  previewSection: {
    padding: theme.spacing(3),
    marginTop: theme.spacing(3),
    backgroundColor: theme.palette.grey[50],
    borderRadius: 8,
  },
  thumbnailPreview: {
    maxWidth: "100%",
    maxHeight: 200,
    borderRadius: 4,
    marginTop: theme.spacing(1),
  },
  keywordChip: {
    marginRight: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
  },
  uploadButton: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  submitButton: {
    padding: theme.spacing(1.5),
    fontWeight: "bold",
    fontSize: "1rem",
    marginTop: theme.spacing(2),
  },
}));

const CreateBlog = () => {
  const classes = useStyles();
  const editor = useRef(null);
  const [slug, setSlug] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [content, setContent] = useState("");
  const [keywords, setKeywords] = useState("");
  const [thumbnail, setThumbnail] = useState(null);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [user, userLoading] = useAuthState(auth);
  const [previewThumbnail, setPreviewThumbnail] = useState(null);

  const handleThumbnailChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setThumbnail(file);
      // Create preview URL
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewThumbnail(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setSuccess(false);
    setLoading(true);

    if (!slug || !title || !description || !content || !keywords || !thumbnail) {
      setError("All fields are required.");
      setLoading(false);
      return;
    }

    const slugQuery = query(collection(db, "blogs"), where("slug", "==", slug));
    const slugSnapshot = await getDocs(slugQuery);
    if (!slugSnapshot.empty) {
      setError("Slug already exists. Please choose a different one.");
      setLoading(false);
      return;
    }

    try {
      const thumbnailRef = ref(storage, `thumbnails/${thumbnail.name}_${Date.now()}`);
      await uploadBytes(thumbnailRef, thumbnail);
      const thumbnailURL = await getDownloadURL(thumbnailRef);
      const docRef = doc(db, "users", user.uid);
      const docSnap = await getDoc(docRef);

      await addDoc(collection(db, "blogs"), {
        slug,
        title,
        description,
        content,
        keywords: keywords.split(",").map((kw) => kw.trim()),
        thumbnail: thumbnailURL,
        author: user.uid,
        authorname: user.displayName || user.email,
        timestamp: new Date(),
        status: "pending",
      });
      
      // Reset form
      setSlug("");
      setTitle("");
      setDescription("");
      setContent("");
      setKeywords("");
      setThumbnail(null);
      setPreviewThumbnail(null);
      setSuccess(true);
    } catch (err) {
      setError("Error submitting blog: " + err.message);
    }
    setLoading(false);
  };

  const config = {
    readonly: false,
    placeholder: "Write your blog content here...",
    buttons: [
      "bold", "italic", "underline", "strikethrough", "|",
      "ul", "ol", "|",
      "font", "fontsize", "brush", "paragraph", "|",
      "align", "|",
      "image", "video", "table", "link", "|",
      "undo", "redo", "|",
      "hr", "eraser", "fullsize"
    ],
    height: 400,
  };

  if (userLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="60vh">
        <CircularProgress size={60} />
      </Box>
    );
  }

  if (!user) {
    return (
      <Container maxWidth="sm" className={classes.root}>
        <Card className={classes.card}>
          <CardHeader
            title="Access Required"
            avatar={
              <Avatar className={classes.avatar}>
                <InfoIcon />
              </Avatar>
            }
          />
          <CardContent>
            <Typography variant="body1" align="center" paragraph>
              Please log in to create a blog post.
            </Typography>
            <Box display="flex" justifyContent="center" mt={2}>
              <Button 
                variant="contained" 
                color="primary" 
                onClick={() => auth.signInWithRedirect(/* your auth provider */)}
              >
                Sign In
              </Button>
            </Box>
          </CardContent>
        </Card>
      </Container>
    );
  }

  return (
    <Container maxWidth="md" className={classes.root}>
      <Card className={classes.card}>
        <CardHeader
          title="Create New Blog Post"
          subheader="Share your knowledge with the community"
          className={classes.header}
          avatar={
            <Avatar src={user.photoURL} className={classes.avatar}>
              {user.displayName ? user.displayName.charAt(0) : user.email.charAt(0)}
            </Avatar>
          }
        />
        
        <CardContent>
          {error && (
            <Alert severity="error" sx={{ mb: 3 }}>
              {error}
            </Alert>
          )}
          
          {success && (
            <Alert 
              severity="success" 
              icon={<CheckCircleIcon fontSize="inherit" />}
              sx={{ mb: 3 }}
            >
              Blog submitted successfully! It's now pending review.
            </Alert>
          )}

          <Box component="form" onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Slug"
                  value={slug}
                  onChange={(e) => setSlug(e.target.value)}
                  required
                  fullWidth
                  margin="normal"
                  helperText="The URL-friendly version of your title (e.g., 'my-awesome-post')"
                  variant="outlined"
                />
              </Grid>
              
              <Grid item xs={12} md={6}>
                <TextField
                  label="Title"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  required
                  fullWidth
                  margin="normal"
                  variant="outlined"
                />
              </Grid>
              
              <Grid item xs={12}>
                <TextField
                  label="Description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  required
                  fullWidth
                  multiline
                  rows={3}
                  margin="normal"
                  helperText="A brief summary of your blog post"
                  variant="outlined"
                />
              </Grid>
              
              <Grid item xs={12}>
                <FormControl fullWidth margin="normal">
                  <FormLabel>Content</FormLabel>
                  <Box className={classes.editorContainer}>
                    <JoditEditor
                      ref={editor}
                      value={content}
                      config={config}
                      onBlur={(newContent) => setContent(newContent)}
                      onChange={(newContent) => {}}
                    />
                  </Box>
                  <FormHelperText>Write your detailed content here</FormHelperText>
                </FormControl>
              </Grid>
              
              <Grid item xs={12}>
                <TextField
                  label="Keywords"
                  value={keywords}
                  onChange={(e) => setKeywords(e.target.value)}
                  required
                  fullWidth
                  margin="normal"
                  helperText="Comma-separated keywords for SEO (e.g., 'react, web development, tutorial')"
                  variant="outlined"
                />
                <Box mt={1}>
                  {keywords.split(",").filter(kw => kw.trim()).map((keyword, index) => (
                    <Chip
                      key={index}
                      label={keyword.trim()}
                      className={classes.keywordChip}
                      size="small"
                    />
                  ))}
                </Box>
              </Grid>
              
              <Grid item xs={12}>
                <FormControl fullWidth margin="normal">
                  <FormLabel>Thumbnail Image</FormLabel>
                  <input
                    accept="image/*"
                    style={{ display: 'none' }}
                    id="thumbnail-upload"
                    type="file"
                    onChange={handleThumbnailChange}
                    required
                  />
                  <label htmlFor="thumbnail-upload">
                    <Button
                      variant="outlined"
                      component="span"
                      startIcon={<CloudUploadIcon />}
                      className={classes.uploadButton}
                    >
                      Upload Thumbnail
                    </Button>
                  </label>
                  {previewThumbnail && (
                    <Box mt={1}>
                      <Typography variant="subtitle2">Preview:</Typography>
                      <img 
                        src={previewThumbnail} 
                        alt="Thumbnail preview" 
                        className={classes.thumbnailPreview}
                      />
                    </Box>
                  )}
                  <FormHelperText>
                    Recommended size: 1200x630 pixels (will be resized if needed)
                  </FormHelperText>
                </FormControl>
              </Grid>
            </Grid>

            <Divider sx={{ my: 3 }} />

            <Box display="flex" justifyContent="flex-end">
              <Button
                type="submit"
                variant="contained"
                color="primary"
                size="large"
                className={classes.submitButton}
                disabled={loading}
                startIcon={loading ? <CircularProgress size={20} /> : null}
              >
                {loading ? "Submitting..." : "Publish Blog"}
              </Button>
            </Box>
          </Box>
        </CardContent>
      </Card>

      {/* Preview Section */}
      {(title || description || content) && (
        <Paper elevation={0} className={classes.previewSection}>
          <Typography variant="h6" gutterBottom>
            Blog Preview
          </Typography>
          <Divider sx={{ mb: 2 }} />
          
          {title && (
            <Typography variant="h4" gutterBottom>
              {title}
            </Typography>
          )}
          
          {description && (
            <Typography variant="subtitle1" color="textSecondary" paragraph>
              {description}
            </Typography>
          )}
          
          {previewThumbnail && (
            <Box mb={2}>
              <img 
                src={previewThumbnail} 
                alt="Blog thumbnail preview" 
                style={{ maxWidth: '100%', borderRadius: 8 }}
              />
            </Box>
          )}
          
          {content && (
            <Box 
              dangerouslySetInnerHTML={{ __html: content }} 
              sx={{
                "& img": { maxWidth: '100%', height: 'auto' },
                "& iframe": { maxWidth: '100%' }
              }}
            />
          )}
        </Paper>
      )}
    </Container>
  );
};

export default CreateBlog;