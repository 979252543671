import { useEffect, useState } from "react";
import "../css/laoutcss2.css";

export const SideBar = () => {
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        const navbar = document.getElementById("nav-bar");
        const body = document.body;

        if (isOpen) {
            navbar.classList.add("show");
            body.classList.add("body-pd");
        } else {
            navbar.classList.remove("show");
            body.classList.remove("body-pd");
        }
    }, [isOpen]);

    return (
        <>
            <header className="header" id="header">
                <div className="header__toggle">
                    <i
                        className={isOpen ? "bx bx-x" : "bx bx-menu"}
                        id="header-toggle"
                        onClick={() => setIsOpen(!isOpen)}
                    ></i>
                </div>
            </header>

            <div className="l-navbar" id="nav-bar">
                <nav className="nav">
                    {/* Close button at the top-right of the sidebar */}
                    { isOpen &&
                    <div className="nav__close" onClick={() => setIsOpen(false)}>
                     
                        <i className="bx bx-x"></i>

                    </div>
}

                    <div>
                        <a href="/" className="nav__logo">
                            <i className="bx bx-layer nav__logo-icon"></i>
                            <span className="nav__logo-name">FreeSolutions.In</span>
                        </a>
                        <div className="nav__list">
                            <a href="image-to-url-convert" className="nav__link active">
                                <i className="bx bx-link-alt nav__icon"></i>
                                <span className="nav__name">Image to URL</span>
                            </a>
                            <a href="image-to-pdf" className="nav__link">
                                <i className="bx bx-file nav__icon"></i>
                                <span className="nav__name">Image to PDF</span>
                            </a>
                            <a href="image-resize" className="nav__link">
                                <i className="bx bx-images nav__icon"></i>
                                <span className="nav__name">Image Resizer</span>
                            </a>
                            <a href="image-to-text-converter" className="nav__link">
                                <i className="bx bx-text nav__icon"></i>
                                <span className="nav__name">Image to Text</span>
                            </a>
                            <a href="pdf-merge-online-free" className="nav__link">
                                <i className="bx bx-merge nav__icon"></i>
                                <span className="nav__name">PDF Merger</span>
                            </a>
                            <a href="pdf-to-word-converter" className="nav__link">
                                <i className="bx bx-file-blank nav__icon"></i>
                                <span className="nav__name">PDF to Word</span>
                            </a>
                            <a href="age-calculator" className="nav__link">
                                <i className="bx bx-calculator nav__icon"></i>
                                <span className="nav__name">Age Calculator</span>
                            </a>
                            <a href="lucky-people" className="nav__link">
                                <i className="bx bx-check-circle nav__icon"></i>
                                <span className="nav__name">Check Luck</span>
                            </a>
                            <a href="strong-password-generator" className="nav__link">
                                <i className="bx bx-star nav__icon"></i>
                                <span className="nav__name">Password Generator</span>
                            </a>
                            <a href="#" className="nav__link">
                                <i className="bx bx-link-external nav__icon"></i>
                                <span className="nav__name">URL Summarizer</span>
                            </a>
                            <a href="youtube-embed-code-generator" className="nav__link">
                                <i className="bx bx-code-alt nav__icon"></i>
                                <span className="nav__name">Youtube Embed Code</span>
                            </a>
                            <a href="iframe-generator-free" className="nav__link">
                                <i className="bx bx-video nav__icon"></i>
                                <span className="nav__name">Iframe Generator</span>
                            </a>
                        </div>
                    </div>
                </nav>
            </div>
        </>
    );
};
