import React, { useState } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  Container,
  Typography,
  Box,
  TextField,
  Button,
  CircularProgress,
  Paper,
  Grid,
  FormControlLabel,
  Switch,
} from '@mui/material';

const ImageResizer = () => {
  const [file, setFile] = useState(null);
  const [width, setWidth] = useState('');
  const [height, setHeight] = useState('');
  const [aspectRatioLock, setAspectRatioLock] = useState(false); // Aspect ratio lock state
  const [imagePreview, setImagePreview] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [downloadLink, setDownloadLink] = useState('');

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
      setImagePreview(URL.createObjectURL(selectedFile));
    }
  };

  const handleWidthChange = (e) => {
    const newWidth = e.target.value;
    setWidth(newWidth);

    // Adjust height if aspect ratio is locked
    if (aspectRatioLock && file) {
      const img = new Image();
      img.src = imagePreview;
      img.onload = () => {
        const ratio = img.width / img.height;
        setHeight(Math.round(newWidth / ratio));
      };
    }
  };

  const handleHeightChange = (e) => {
    const newHeight = e.target.value;
    setHeight(newHeight);

    // Adjust width if aspect ratio is locked
    if (aspectRatioLock && file) {
      const img = new Image();
      img.src = imagePreview;
      img.onload = () => {
        const ratio = img.width / img.height;
        setWidth(Math.round(newHeight * ratio));
      };
    }
  };

  const handleResize = async () => {
    if (!file || !width || !height) {
      toast.error('Please upload an image and provide dimensions.');
      return;
    }

    const formData = new FormData();
    formData.append('file', file);
    formData.append('width', width);
    formData.append('height', height);

    setIsLoading(true);

    try {
      const response = await axios.post('https://separately-crisp-titmouse.ngrok-free.app/api/upload', formData, {
        responseType: 'blob',
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      setDownloadLink(url);
      toast.success('Image resized successfully!');
    } catch (error) {
      console.error('Error resizing image:', error);
      toast.error('Failed to resize image.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container maxWidth="md">
      <Box sx={{ mt: 4, textAlign: 'center' }}>
        <Typography variant="h3" gutterBottom>
          Image Resizer
        </Typography>
        <Paper elevation={3} sx={{ p: 4, mt: 2 }}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <input
                type="file"
                accept="image/*"
                onChange={handleFileChange}
                style={{ display: 'none' }}
                id="upload-button"
              />
              <label htmlFor="upload-button">
                <Button variant="contained" component="span">
                  Upload Image
                </Button>
              </label>
              {imagePreview && (
                <Box sx={{ mt: 2 }}>
                  <img
                    src={imagePreview}
                    alt="Preview"
                    style={{ maxWidth: '100%', maxHeight: '300px', borderRadius: '8px' }}
                  />
                </Box>
              )}
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Width (px)"
                type="number"
                fullWidth
                value={width}
                onChange={handleWidthChange}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Height (px)"
                type="number"
                fullWidth
                value={height}
                onChange={handleHeightChange}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Switch
                    checked={aspectRatioLock}
                    onChange={(e) => setAspectRatioLock(e.target.checked)}
                    color="primary"
                  />
                }
                label="Lock Aspect Ratio"
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleResize}
                disabled={isLoading}
                fullWidth
              >
                {isLoading ? <CircularProgress size={24} /> : 'Resize Image'}
              </Button>
            </Grid>
            {downloadLink && (
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="success"
                  fullWidth
                  href={downloadLink}
                  download="resized_image.png"
                >
                  Download Resized Image
                </Button>
              </Grid>
            )}
          </Grid>
        </Paper>
      </Box>
      <ToastContainer />
    </Container>
  );
};

export default ImageResizer;