import React, { useState } from 'react';
import {
  Container,
  Typography,
  Box,
  Slider,
  FormControlLabel,
  Checkbox,
  Button,
  Paper,
  TextField,
} from '@mui/material';

const PasswordGenerator = () => {
  const [passwordLength, setPasswordLength] = useState(12);
  const [uppercase, setUppercase] = useState(true);
  const [lowercase, setLowercase] = useState(true);
  const [numbers, setNumbers] = useState(true);
  const [symbols, setSymbols] = useState(true);
  const [easyToSay, setEasyToSay] = useState(false);
  const [easyToRead, setEasyToRead] = useState(false);
  const [password, setPassword] = useState('');

  // Character sets
  const uppercaseChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const lowercaseChars = 'abcdefghijklmnopqrstuvwxyz';
  const numberChars = '0123456789';
  const symbolChars = '!@#$%^&*()_+-=[]{}|;:,.<>?';

  // Generate password
  const generatePassword = () => {
    let charset = '';

    if (uppercase) charset += uppercaseChars;
    if (lowercase) charset += lowercaseChars;
    if (numbers) charset += numberChars;
    if (symbols) charset += symbolChars;

    // Apply readability filters
    if (easyToSay) {
      charset = charset.replace(/[l1O0]/g, '');
    }
    if (easyToRead) {
      charset = charset.replace(/[ij1l]/g, '');
    }

    if (!charset) {
      alert('Please select at least one character type.');
      return;
    }

    let generatedPassword = '';
    for (let i = 0; i < passwordLength; i++) {
      const randomIndex = Math.floor(Math.random() * charset.length);
      generatedPassword += charset[randomIndex];
    }

    setPassword(generatedPassword);
  };

  // Copy password to clipboard
  const copyPassword = () => {
    navigator.clipboard.writeText(password);
    alert('Password copied to clipboard!');
  };

  return (
    <Container maxWidth="md">
      <Box sx={{ mt: 4, textAlign: 'center' }}>
        <Typography variant="h3" gutterBottom>
          Advanced Password Generator
        </Typography>
        <Paper elevation={3} sx={{ p: 4, mt: 2 }}>
          <Typography variant="h6" gutterBottom>
            Password Length: {passwordLength}
          </Typography>
          <Slider
            value={passwordLength}
            onChange={(e, value) => setPasswordLength(value)}
            min={6}
            max={32}
            step={1}
            sx={{ mb: 2 }}
          />
          <Box sx={{ mb: 2 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={uppercase}
                  onChange={(e) => setUppercase(e.target.checked)}
                  color="primary"
                />
              }
              label="Uppercase (A-Z)"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={lowercase}
                  onChange={(e) => setLowercase(e.target.checked)}
                  color="primary"
                />
              }
              label="Lowercase (a-z)"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={numbers}
                  onChange={(e) => setNumbers(e.target.checked)}
                  color="primary"
                />
              }
              label="Numbers (0-9)"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={symbols}
                  onChange={(e) => setSymbols(e.target.checked)}
                  color="primary"
                />
              }
              label="Symbols (!@#$%^&*)"
            />
          </Box>
          <Box sx={{ mb: 2 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={easyToSay}
                  onChange={(e) => setEasyToSay(e.target.checked)}
                  color="primary"
                />
              }
              label="Easy to Say"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={easyToRead}
                  onChange={(e) => setEasyToRead(e.target.checked)}
                  color="primary"
                />
              }
              label="Easy to Read"
            />
          </Box>
          <Button
            variant="contained"
            color="primary"
            onClick={generatePassword}
            fullWidth
            sx={{ mb: 2 }}
          >
            Generate Password
          </Button>
          {password && (
            <Box sx={{ mt: 2 }}>
              <TextField
                value={password}
                fullWidth
                variant="outlined"
                InputProps={{
                  readOnly: true,
                }}
                sx={{ mb: 2 }}
              />
              <Button
                variant="contained"
                color="secondary"
                onClick={copyPassword}
                fullWidth
              >
                Copy Password
              </Button>
            </Box>
          )}
        </Paper>
      </Box>
    </Container>
  );
};

export default PasswordGenerator;