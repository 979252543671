import IframeGenerator from "../components/iframeGenerator/iframeGenerator";
import { SideBar } from "../components/sidebar";

export const IframeGeneratorPage=()=>{
    return(
        <>
        <SideBar/>
        <IframeGenerator/>
        </>
    )
}