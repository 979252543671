import React from "react";
import { Box, Typography, Button, Paper } from "@mui/material";
import { useNavigate } from "react-router-dom";

const HorizontalPromoBar = () => {
  return (
    <Paper
      elevation={1}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        p: 2,
        backgroundColor: "#f8f9fa",
        borderRadius: 1,
        flexWrap: 'wrap',
        gap: 2
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
        <Typography variant="subtitle1" fontWeight="bold">
          ✨ Start publishing blogs for free!
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Earn ₹100 per post
        </Typography>
      </Box>
      <Button
        variant="contained"
        color="primary"
        size="small"
        href="/signup"
        sx={{ whiteSpace: 'nowrap' }}
      >
        Get Started
      </Button>
    </Paper>
  );
};

export default HorizontalPromoBar;