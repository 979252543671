import 'bootstrap'
export const AllTools=()=>{


    return (

        <>
         <section class="banner_wrapper" id="home">
         <div class="container">
            <div class="row align-items-center">
                <div class="col-12">
                    <div class="title-box text-center">
                        <h1>Welcome to the <span>Freesolutions.in</span></h1>
                        <h3>select a tool from below options</h3>
                        <p>Explore our new and exciting features and <br class="d-block d-lg-none"/> make the most of
                            your tasks.</p>
                    </div>
                </div>
            </div>

            <div class="row justify-content-center">
                <div class="col-lg-4 col-md-6 col-12 text-center mb-4 mb-lg-0">
                    <div class="banner-buttons-group">
                        <div class="button01">
                            <div class="btn01-head">
                                <img src="https://i.ibb.co/mRcQWV4/pdf-word.png" alt="pdf-word" class="img-fluid"/>
                                <h3>PDF to Word</h3>
                            </div>
                            <p>Convert a PDF to a Word file in a few simple steps. Drag & drop or choose a document to
                                begin.</p>
                            <a href="pdf-to-word-converter" class="main-btn">Open</a>

                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 col-12 text-center mb-3 mb-lg-0">
                    <div class="banner-buttons-group">
                        <div class="button01">
                            <div class="btn01-head">
                                <img src="https://i.ibb.co/0ym1bxN/merger.webp" alt="pdf-merger" class="img-fluid"/>
                                <h3>PDF Merger</h3>
                            </div>
                            <p>Combine PDF files to create a single document. It’s easy to merge PDFs with our PDF
                                combiner.</p>
                            <a href="pdf-merge-online-free" class="main-btn">Open</a>

                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 col-12 text-center mb-3 mb-lg-0">
                    <div class="banner-buttons-group">
                        <div class="button01">
                            <div class="btn01-head">
                                <img src="https://i.ibb.co/178MfTh/image-to-pdf.png" alt="img-pdf" class="img-fluid"/>
                                <h3>Image to PDF</h3>
                            </div>
                            <p>Convert an Image to a PDF file in a few simple steps. Drag & drop or choose a document to
                                begin.</p>
                            <a href="image-to-pdf" class="main-btn">Open</a>

                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 col-12 text-center">
                    <div class="banner-buttons-group">
                        <div class="button01">
                            <div class="btn01-head">
                                <img src="https://i.ibb.co/1L8ChWj/summarizer.png" alt="summarizer" class="img-fluid"/>
                                <h3>URL Summarizer</h3>
                            </div>
                            <p>Summarize URL in a few simple steps. It’s easy to merge URLs with our URL Summarizer.
                            </p>
                            <a href="url-summarizer" class="main-btn">Open</a>

                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 col-12 text-center">
                    <div class="banner-buttons-group">
                        <div class="button01">
                            <div class="btn01-head">
                                <img src="https://i.ibb.co/CHgr72p/img-resize.png" alt="img-resizer" class="img-fluid"/>
                                <h3>Image Resize</h3>
                            </div>
                            <p>Resize an Image file in a few simple steps. Drag & drop or choose a image to
                                starts with.</p>
                            <a href="image-resize" class="main-btn">Open</a>

                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-12 text-center">
                    <div class="banner-buttons-group">
                        <div class="button01">
                            <div class="btn01-head">
                                <img src="https://i.ibb.co/Mn0rjpX/image-to-text.png" alt="img-text" class="img-fluid"/>
                                <h3>Image to Text</h3>
                            </div>
                            <p>Convert an Image to Text in a few simple steps. Drag & drop or choose a image to
                                starts with.</p>
                            <a href="image-to-text-converter" class="main-btn">Open</a>

                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-12 text-center">
                    <div class="banner-buttons-group">
                        <div class="button01">
                            <div class="btn01-head">
                                <img src="https://i.ibb.co/drBkjPG/img-url.png" alt="img-url" class="img-fluid"/>
                                <h3>Image to URL</h3>
                            </div>
                            <p>Convert an Image to an URL in a few simple steps. Drag & drop or choose a image to
                                starts with.</p>
                            <a href="image-to-url-convert" class="main-btn">Open</a>

                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-12 text-center">
                    <div class="banner-buttons-group">
                        <div class="button01">
                            <div class="btn01-head">
                                <img src="https://i.ibb.co/h15zhTf/age-calc.png" alt="age-calculator" class="img-fluid"/>
                                <h3>Age Calculator</h3>
                            </div>
                            <p>Calculate your age from date of birth. Find how old you are in years, months, weeks and
                                days online.</p>
                            <a href="age-calculator" class="main-btn">Open</a>

                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-12 text-center">
                    <div class="banner-buttons-group">
                        <div class="button01">
                            <div class="btn01-head">
                                <img src="https://i.ibb.co/ng0MkRj/password-generator.png" alt="password-generator" class="img-fluid"/>
                                <h3>Password Generator</h3>
                            </div>
                            <p>Generate strong & secure passwords for all your accounts with our random password
                                generator.</p>
                            <a href="strong-password-generator" class="main-btn">Open</a>

                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-12 text-center">
                    <div class="banner-buttons-group">
                        <div class="button01">
                            <div class="btn01-head">
                                <img src="https://i.ibb.co/1X8v0Qs/check-luck.webp" alt="luck-fortune" class="img-fluid"/>
                                <h3>Check your Luck</h3>
                            </div>
                            <p>Explore our fascinating 'How Lucky are You? A Luck Test' and uncover the secrets of your
                                fortune.</p>
                            <a href="lucky-people" class="main-btn">Open</a>

                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-12 text-center">
                    <div class="banner-buttons-group">
                        <div class="button01">
                            <div class="btn01-head">
                                <img src="https://i.ibb.co/C9d0NMF/youtube.png" alt="luck-fortune" class="img-fluid"/>
                                <h3>Youtube embed code</h3>
                            </div>
                            <p>Generate YouTube Embed Code Instantly.</p>
                            <a href="youtube-embed-code-generator" class="main-btn">Open</a>

                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-12 text-center">
                    <div class="banner-buttons-group">
                        <div class="button01">
                            <div class="btn01-head">
                                <img src="https://i.ibb.co/DQgGYPq/ux.png" alt="luck-fortune" class="img-fluid"/>
                                <h3> Iframe Generator</h3>
                            </div>
                            <p>Create Iframe Embed Code For Any Url Instantly.</p>
                            <a href="iframe-generator-free" class="main-btn">Open</a>

                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 col-12 text-center">
                    <div class="banner-buttons-group">
                        <div class="button01">
                            <div class="btn01-head">
                                <img src="https://i.ibb.co/DQgGYPq/ux.png" alt="sitemap-generator" class="img-fluid"/>
                                <h3> Sitemap Generator</h3>
                            </div>
                            <p>Create Sitemap.xml file for Any website Url and download Instantly.</p>
                            <a href="sitemap-generator" class="main-btn">Open</a>

                        </div>
                    </div>
                </div>

            </div>
        </div>
        </section>
        </>
    )
}