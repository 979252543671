import React from "react";
import { auth } from "../firebase/firebase"; // Adjust the path to your Firebase config
import { useAuthState } from "react-firebase-hooks/auth";
import { signOut } from "firebase/auth";

export const Header = () => {
  const [user] = useAuthState(auth);

  const handleSignOut = async () => {
    try {
      await signOut(auth);
    } catch (err) {
      console.error("Error signing out:", err);
    }
  };

  // Function to close navbar on item click
  const closeNavbar = () => {
    const navbarCollapse = document.getElementById("navbarNav");
    if (navbarCollapse.classList.contains("show")) {
      navbarCollapse.classList.remove("show");
    }
  };

  return (
    <header className="header_wrapper">
      <nav className="navbar navbar-expand-lg">
        <div className="container">
          <a className="navbar-brand" href="/">
            FreeSolutions.in
          </a>
          
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <i className="fa-solid navbar-toggler-icon"></i>
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav ms-auto menu-navbar-nav">
              <li className="nav-item">
                <a className="nav-link active" href="/" onClick={closeNavbar}>
                  Home
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/blogs" onClick={closeNavbar}>
                  Blog
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/contact" onClick={closeNavbar}>
                  Contact Us
                </a>
              </li>

              {user ? (
                <a href="/user-dashboard" className="main-btn" onClick={closeNavbar}>
                  Dashboard
                </a>
              ) : null}

              <li className="nav-item mt-3 mt-lg-0">
                {user ? (
                  <button className="main-btn" onClick={() => { handleSignOut(); closeNavbar(); }}>
                    Sign Out
                  </button>
                ) : (
                  <a href="/login" className="main-btn" onClick={closeNavbar}>
                    Sign In
                  </a>
                )}
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
};
