import React, { useState } from "react";
import { auth } from "../firebase/firebase";
import { signInWithEmailAndPassword } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { signInWithPopup } from "firebase/auth";
import { setDoc,getDoc,doc } from "firebase/firestore";
import { db } from "../firebase/firebase";
import { googleProvider } from "../firebase/firebase";
import {
  Container,
  Typography,
  TextField,
  Button,
  Box,
  Link,
  Alert,
  CircularProgress,
} from "@mui/material";
import {makeStyles} from "@mui/styles";
//import makeStyles from "@mui/material";
import GoogleIcon from "@mui/icons-material/Google";

const useStyles = makeStyles((theme) => ({
    root: {
      marginTop: theme.spacing(8),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    paper: {
      padding: theme.spacing(4),
      width: "100%",
      maxWidth: 400,
      textAlign: "center",
    },
    form: {
      width: "100%",
      marginTop: theme.spacing(2),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
    googleButton: {
      margin: theme.spacing(2, 0),
      backgroundColor: "#4285F4",
      color: "#fff",
      "&:hover": {
        backgroundColor: "#357ABD",
      },
    },
    error: {
      color: "red",
      marginTop: theme.spacing(2),
    },
    progress: {
      margin: theme.spacing(2),
    },
  }));
  
const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
  const navigate = useNavigate();
  const classes = useStyles();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      await signInWithEmailAndPassword(auth, email, password);
      navigate("/user-dashboard"); // Redirect to home page after login
    } catch (err) {
      setError(err.message);
    }
  };


   const handleGoogleSignIn = async () => {
      setLoading(true);
      setError("");
      try {
        const result = await signInWithPopup(auth, googleProvider);
        const user = result.user;
  
        // Save user data to Firestore
        const userDoc = await getDoc(doc(db, "users", user.uid));
        if (!userDoc.exists()) {
        await setDoc(doc(db, "users", user.uid), {
          uid: user.uid,
          name: user.displayName,
          email: user.email,
          photoURL: user.photoURL,
          provider: "google",
          createdAt: new Date(),
        });
      }
        setSuccess(true);
        setTimeout(() => navigate("/user-dashboard"), 2000); // Redirect to home page after 2 seconds
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    const handleCloseSnackbar = () => {
        setSuccess(false);
      };


     

  return (
    <Container maxWidth="sm">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography component="h1" variant="h4" sx={{ mb: 2 }}>
          Login
        </Typography>
        {error && (
          <Alert severity="error" sx={{ width: "100%", mb: 2 }}>
            {error}
          </Alert>
        )}
        <Box component="form" onSubmit={handleLogin} sx={{ mt: 1, width: "100%" }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Login
          </Button>
            <Button
                    fullWidth
                    variant="contained"
                    className={classes.googleButton}
                    startIcon={<GoogleIcon />}
                    onClick={handleGoogleSignIn}
                    disabled={loading}
                  >
                    {loading ? <CircularProgress size={24} /> : "SignIn with Google"}
                  </Button>
          <Typography variant="body2" sx={{ textAlign: "center" }}>
            Don't have an account?{" "}
            <Link href="/signup" underline="hover">
              Sign up
            </Link>
          </Typography>
        </Box>
      </Box>
    </Container>
  );
};

export default Login;