export const ImageToUrlContent =()=>{


    return (
        <>
    <section class="banner_wrapper" id="blog">
    <div class="container">
        <div class="row align-items-center mt-5">
            <div class="col-12 text-center text-lg-start pt-3 pb-3">
                <div class="text-content">
                    <h3>How to Use the <span>Image to URL </span>Converter</h3>
                    <div class="d-flex mt-3 text-content2">
                        <h5>1. Upload Image:</h5>
                        <p> Look for a "Upload" or "Choose File" button. Click on the button to open your computer's
                            file picker. Select the image you want to convert into a URL.
                            Conversion Process: Once you've uploaded the image, the tool will process it. The tool
                            will generate a unique URL that corresponds to the uploaded image.</p>
                    </div>
                    <div class="d-flex mt-3 text-content2">
                        <h5>2. Conversion Process:</h5>
                        <p> Once you've uploaded the image, the tool will process it. The tool will generate a
                            unique URL that corresponds to the uploaded image</p>
                    </div>
                    <div class="d-flex mt-3 text-content2">
                        <h5>3. Copy URL:</h5>
                        <p>After the conversion process is complete, the generated URL will be displayed on the
                            screen. Highlight the URL and use the keyboard shortcut (Ctrl+C or Command+C) to copy it
                            to your clipboard.</p>
                    </div>
                    <div class="d-flex mt-3 text-content2">
                        <h5>4. Share or Embed:</h5>
                        <p> Now that you have the URL, you can easily share it with others. Paste the URL into
                            emails, messages, social media posts, or documents to share the image. If you're working
                            with websites, you can embed the image by using the URL in an HTML "img" tag.</p>
                    </div>
                    <div class="d-flex mt-3 text-content2">
                        <h5>5. Accessible Image:</h5>
                        <p> Anyone with the URL can access the image directly in their browser by pasting the URL
                            into the address bar. The image will be displayed without the need for downloading or
                            opening attachments.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="banner_wrapper" id="blog">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-12 text-center text-lg-start pt-3 pb-3">
                <div class="text-content">
                    <h3>Tips for <span>Effective Use</span></h3>
                    <div class="d-flex mt-3 text-content2">
                        <h5>Image Privacy:</h5>
                        <p> Be cautious when sharing sensitive or private images via URL. Anyone with the URL can
                            access the image.</p>
                    </div>
                    <div class="d-flex mt-3 text-content2">
                        <h5>Image Size:</h5>
                        <p>Consider the size of the image and the hosting platform. Some platforms may have
                            restrictions on image size or bandwidth usage.</p>
                    </div>
                    <div class="d-flex mt-3 text-content2">
                        <h5>Longevity:</h5>
                        <p>URLs may expire or change over time. Ensure that the URL remains accessible for as long
                            as you need.</p>
                    </div>
                    <div class="d-flex mt-3 text-content2">
                        <h5>Platform Limitations:</h5>
                        <p> Some platforms may have restrictions on the types of images you can upload or the
                            maximum file size allowed.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="banner_wrapper" id="blog">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-12 text-center text-lg-start pt-3 pb-3">
                <div class="text-content ">
                    <h3 class="pb-3">Common <span>Use Cases</span></h3>
                    <div class="d-flex mt-3 text-content2">
                        <p>1. Sharing images in emails without large attachments.</p>
                    </div>
                    <div class="d-flex mt-1 text-content2">
                        <p>2. Embedding images in blog posts, articles, or forum threads.</p>
                    </div>
                    <div class="d-flex mt-1 text-content2">
                        <p>3. Providing direct links to images for use in presentations or documents.</p>
                    </div>
                    <div class="d-flex mt-1 text-content2">
                        <p>4. Sharing event photos quickly and conveniently.</p>
                    </div>
                    <div class="d-flex mt-1 text-content2">
                        <p>5. Displaying product images in e-commerce listings or online catalogs.</p>
                    </div>

                </div>
            </div>
        </div>
    </div>
    <div class="container-fluid">
        <div class="row">
            <div class="col-12 text-center text-lg-start">
                <p class="para">By using an Image to URL Converter, you can streamline the process of sharing images online, improve collaboration, and enhance the visual content experience for your audience.</p>
            </div>
        </div>
    </div>
</section>

</>
    )
}