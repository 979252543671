import React, { useState } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  Container,
  Typography,
  Box,
  TextField,
  Button,
  Paper,
} from '@mui/material';

const AgeCalculator = () => {
  const [birthdate, setBirthdate] = useState('');
  const [age, setAge] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleCalculate = async () => {
    if (!birthdate) {
      toast.error('Please enter your birthdate.');
      return;
    }

    setIsLoading(true);

    try {
      const response = await axios.post('https://separately-crisp-titmouse.ngrok-free.app//api/age/calculate', {
        birthdate: birthdate,
      });

      setAge(response.data);
      toast.success('Age calculated successfully!');
    } catch (error) {
      console.error('Error calculating age:', error);
      toast.error('Failed to calculate age.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container maxWidth="md">
      <Box sx={{ mt: 4, textAlign: 'center' }}>
        <Typography variant="h3" gutterBottom>
          Age Calculator
        </Typography>
        <Paper elevation={3} sx={{ p: 4, mt: 2 }}>
          <TextField
            label="Birthdate"
            type="date"
            fullWidth
            value={birthdate}
            onChange={(e) => setBirthdate(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
            sx={{ mb: 2 }}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleCalculate}
            disabled={isLoading}
            fullWidth
          >
            {isLoading ? 'Calculating...' : 'Calculate Age'}
          </Button>
          {age && (
            <Box sx={{ mt: 2 }}>
              <Typography variant="h6">
                Your age is: {age.years} years, {age.months} months, and {age.days} days.
              </Typography>
            </Box>
          )}
        </Paper>
      </Box>
      <ToastContainer />
    </Container>
  );
};

export default AgeCalculator;