import React, { useState } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  Container,
  Typography,
  Box,
  Button,
  CircularProgress,
  Paper,
  List,
  ListItem,
  ListItemText,
  IconButton,
  LinearProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Switch,
  FormControlLabel,
} from '@mui/material';
import { useDrag, useDrop, DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import DeleteIcon from '@mui/icons-material/Delete';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

// Draggable Image Item Component
const ImageItem = ({ image, index, moveImage, handleDelete }) => {
  const [, ref] = useDrag({
    type: 'IMAGE',
    item: { index },
  });

  const [, drop] = useDrop({
    accept: 'IMAGE',
    hover: (draggedItem) => {
      if (draggedItem.index !== index) {
        moveImage(draggedItem.index, index);
        draggedItem.index = index;
      }
    },
  });

  return (
    <ListItem
      ref={(node) => ref(drop(node))}
      secondaryAction={
        <IconButton edge="end" aria-label="delete" onClick={() => handleDelete(index)}>
          <DeleteIcon />
        </IconButton>
      }
      sx={{ backgroundColor: 'background.paper', mb: 1, borderRadius: 1 }}
    >
      <ListItemText primary={image.name} />
    </ListItem>
  );
};

const ImageToPdf = () => {
  const [images, setImages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [downloadLink, setDownloadLink] = useState('');
  const [orientation, setOrientation] = useState('portrait');
  const [margin, setMargin] = useState(10);
  const [compress, setCompress] = useState(false);

  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    setImages((prevImages) => [...prevImages, ...selectedFiles]);
  };

  const handleDelete = (index) => {
    setImages((prevImages) => prevImages.filter((_, i) => i !== index));
  };

  const moveImage = (fromIndex, toIndex) => {
    const updatedImages = [...images];
    const [movedImage] = updatedImages.splice(fromIndex, 1);
    updatedImages.splice(toIndex, 0, movedImage);
    setImages(updatedImages);
  };

  const handleConvert = async () => {
    if (images.length === 0) {
      toast.error('Please upload at least one image.');
      return;
    }

    const formData = new FormData();
    images.forEach((image) => formData.append('files', image));
    formData.append('orientation', orientation);
    formData.append('margin', margin);
    formData.append('compress', compress);

    setIsLoading(true);
    setUploadProgress(0);

    try {
      const response = await axios.post('https://separately-crisp-titmouse.ngrok-free.app/api/image-to-pdf/convert', formData, {
        responseType: 'blob',
        onUploadProgress: (progressEvent) => {
          const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
          setUploadProgress(progress);
        },
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      setDownloadLink(url);
      toast.success('PDF created successfully!');
    } catch (error) {
      console.error('Error converting images to PDF:', error);
      toast.error('Failed to convert images to PDF.');
    } finally {
      setIsLoading(false);
      setUploadProgress(0);
    }
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <Container maxWidth="md">
        <Box sx={{ mt: 4, textAlign: 'center' }}>
          <Typography variant="h3" gutterBottom>
            Image to PDF Converter
          </Typography>
          <Paper elevation={3} sx={{ p: 4, mt: 2 }}>
            <input
              type="file"
              accept="image/*"
              onChange={handleFileChange}
              style={{ display: 'none' }}
              id="upload-button"
              multiple
            />
            <label htmlFor="upload-button">
              <Button
                variant="contained"
                component="span"
                startIcon={<CloudUploadIcon />}
                fullWidth
              >
                Upload Images
              </Button>
            </label>
            {images.length > 0 && (
              <>
                <List sx={{ mt: 2 }}>
                  {images.map((image, index) => (
                    <ImageItem
                      key={index}
                      image={image}
                      index={index}
                      moveImage={moveImage}
                      handleDelete={handleDelete}
                    />
                  ))}
                </List>
                <FormControl fullWidth sx={{ mt: 2 }}>
                  <InputLabel>Orientation</InputLabel>
                  <Select
                    value={orientation}
                    onChange={(e) => setOrientation(e.target.value)}
                    label="Orientation"
                  >
                    <MenuItem value="portrait">Portrait</MenuItem>
                    <MenuItem value="landscape">Landscape</MenuItem>
                  </Select>
                </FormControl>
                <TextField
                  label="Margin (mm)"
                  type="number"
                  fullWidth
                  value={margin}
                  onChange={(e) => setMargin(e.target.value)}
                  sx={{ mt: 2 }}
                />
                <FormControlLabel
                  control={
                    <Switch
                      checked={compress}
                      onChange={(e) => setCompress(e.target.checked)}
                      color="primary"
                    />
                  }
                  label="Compress Images"
                  sx={{ mt: 2 }}
                />
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleConvert}
                  disabled={isLoading || images.length === 0}
                  fullWidth
                  sx={{ mt: 2 }}
                >
                  {isLoading ? <CircularProgress size={24} /> : 'Convert to PDF'}
                </Button>
                {isLoading && (
                  <Box sx={{ mt: 2 }}>
                    <LinearProgress variant="determinate" value={uploadProgress} />
                    <Typography variant="body2" sx={{ mt: 1 }}>
                      Uploading: {uploadProgress}%
                    </Typography>
                  </Box>
                )}
                {downloadLink && (
                  <Button
                    variant="contained"
                    color="success"
                    fullWidth
                    href={downloadLink}
                    download="converted.pdf"
                    sx={{ mt: 2 }}
                  >
                    Download PDF
                  </Button>
                )}
              </>
            )}
          </Paper>
        </Box>
        <ToastContainer />
      </Container>
    </DndProvider>
  );
};

export default ImageToPdf;
