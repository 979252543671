import PdfMerger from "../components/pdfmerger/pdfMerger"
import { SideBar } from "../components/sidebar"
import PageMetadata from "../components/pageMetaData"
export const PdfMergerPage=()=>{
    return(
        <>
        <PageMetadata   title="Pdf merger for Free - Freesolutions.in"
        description="description" content="Merge multiple pdfs with advance options for free. Freesolutions.in website which provides free online tools for images and pdf"
        keywords="Pdf Merger,merge multiple pdfs, freesolutions, online tools" />
        <SideBar/>
    <PdfMerger/>
    </>
)
}