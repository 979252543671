import ImageToPdf from "../components/imagetopdf/imageToPdf";
import { SideBar } from "../components/sidebar";
import PageMetadata from "../components/pageMetaData";

export const ImageToPdfPage=()=>{

    return(<>
    <PageMetadata   title="Image To Pdf Converter for Free - Freesolutions.in"
        description="description" content="image to Pdf converter for free with advance options. Freesolutions.in website which provides free online tools for images and pdf"
        keywords="Image to Pdf,convert image to pdf, freesolutions, online tools" />
    <SideBar/>
    <ImageToPdf/>
    </>)
}