import CreateBlog from "../../components/blogComponents/createBlog"
import { SideBar } from "../../components/sidebar"

export const CreateBlogPage=()=>{

    return(<>
    <SideBar/>
    <CreateBlog/>
    
    </>)
}