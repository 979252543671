import React, { useEffect, useState } from "react";
import { db } from "../firebase/firebase";
import { collection, query, where, getDocs } from "firebase/firestore";
import { useAuthState } from "react-firebase-hooks/auth";

import { Container, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, CircularProgress, Chip, Button, Box } from "@mui/material";
import { useUser } from "../context/userContext";
import { Height } from "@mui/icons-material";
const UserDashboard = () => {
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);
  //const [user] = useAuthState(auth);
  const {user} = useUser()

  useEffect(() => {
    if (!user) return;

    const fetchUserBlogs = async () => {
      setLoading(true);
      const q = query(collection(db, "blogs"), where("author", "==", user.uid));
      const querySnapshot = await getDocs(q);
      const userBlogs = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      setBlogs(userBlogs);
      setLoading(false);
    };

    fetchUserBlogs();
  }, [user]);

  if (loading) {
    return <Box style={{height:"500px"}}><CircularProgress sx={{ display: "block", margin: "auto", mt: 4 }} /></Box>;
  }

  if (!user) {
    return <Box style={{height:"500px"}}><Typography align="center" mt={4}>Please log in to view your blogs.</Typography></Box>;
  }

  return (
    <Container style={{marginBottom:20}}  maxWidth="md">
      <Typography variant="h4" gutterBottom align="center">
        My Blogs
      </Typography>
      <Button variant="contained" style={{marginRight:"5px"}} href="/createblog">Create New Blog</Button>
      <Button variant="contained" href="/profile">My Profile</Button>
      {blogs.length === 0 ? (
        <Typography height={400} align="center" mt={2}>No blogs found.</Typography>
      ) : (
        <TableContainer component={Paper} sx={{ mt: 2 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell><strong>Title</strong></TableCell>
                <TableCell><strong>Status</strong></TableCell>
                <TableCell><strong>Actions</strong></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {blogs.map((blog) => (
                <TableRow key={blog.id}>
                  <TableCell>{blog.title}</TableCell>
                  <TableCell>
                    <Chip 
                      label={blog.status.charAt(0).toUpperCase() + blog.status.slice(1)}
                      color={
                        blog.status === "approved" ? "success" :
                        blog.status === "pending" ? "warning" : 
                        "error"
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <a href={`/blog/${blog.slug}`} target="_blank" rel="noopener noreferrer">View</a>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Container>
  );
};

export default UserDashboard;
