import React, { useEffect, useState } from "react";
import { db } from "../../firebase/firebase";
import { collection, query, where, getDocs } from "firebase/firestore";
import { Container, Grid, Card, CardContent, CardMedia, Typography, CardActionArea, CircularProgress, Box } from "@mui/material";

const BlogPage = () => {
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchApprovedBlogs = async () => {
      try {
        const q = query(collection(db, "blogs"), where("status", "==", "approved"));
        const querySnapshot = await getDocs(q);
        const blogsList = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        setBlogs(blogsList);
      } catch (error) {
        console.error("Error fetching blogs:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchApprovedBlogs();
  }, []);

  if (loading) {
    return <Box style={{height:"500px"}}><CircularProgress sx={{ display: "block", margin: "auto", mt: 4 }} /></Box> ;
  }

  return (
    <Container maxWidth="lg" sx={{ mt: 4 }}>
      <Typography variant="h4" align="center" gutterBottom>
        Blogs
      </Typography>
      <Grid container mb={3} spacing={3}>
        {blogs.map((blog) => (
          <Grid item xs={12} sm={6} md={4} key={blog.id}>
            <Card sx={{ maxWidth: 345, borderRadius: 3, boxShadow: 3 }}>
              <CardActionArea href={`/blog/${blog.slug}`}>
                {blog.thumbnail && (
                  <CardMedia
                    component="img"
                    height="180"
                    image={blog.thumbnail} 
                    alt={blog.title}
                  />
                )}
                <CardContent>
                  <Typography gutterBottom variant="h6" component="div">
                    {blog.title}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {blog.description.length > 100
                      ? `${blog.description.substring(0, 100)}...`
                      : blog.description}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default BlogPage;
