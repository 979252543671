import { useState, useEffect } from "react";
import { io } from "socket.io-client";

export const PdfToWord = () => {
    const [file, setFile] = useState(null);
    const [progress, setProgress] = useState(0);
    const [convertedFileUrl, setConvertedFileUrl] = useState("");

    useEffect(() => {
        const socket = io("https://separately-crisp-titmouse.ngrok-free.app", { transports: ["websocket", "polling"] });

        socket.on("progress", (data) => {
            setProgress(data.progress);
            console.log(data.progress)
        });

        

        return () => socket.disconnect();
    }, []);

    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
    };

    const handleUpload = async (event) => {
        event.preventDefault();
        if (!file) {
            alert("Please select a PDF file.");
            return;
        }

        setProgress(5); // Start progress
        const formData = new FormData();
        formData.append("pdf_file", file);

        try {
            const response = await fetch("https://separately-crisp-titmouse.ngrok-free.app/api/pdftodocconvert", {
                method: "POST",
                body: formData,
            });

            if (!response.ok) {
                throw new Error("Conversion failed");
            }

            const data = await response.json();
            setConvertedFileUrl(`https://separately-crisp-titmouse.ngrok-free.app${data.download_url}`);
        } catch (error) {
            console.error("Error:", error);
            alert("Conversion failed. Please try again.");
            setProgress(0);
        }
    };

    return (
        <section className="banner_wrapper" id="home">
            <div className="container">
                <div className="row">
                    <div className="col-12 text-center">
                        <div className="text-box">
                            <h1><span>PDF to Word</span> Converter</h1>
                            <p>This free online PDF to Word converter allows you to save<br className="d-lg-block d-none"/> a PDF
                                file as a document in Microsoft Word format.</p>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-lg-12 col-md-10 col-12 text-center mt-4">
                        <div className="pdf-word-box">
                            <form onSubmit={handleUpload} id="convert-form">
                                <div className="custom-file mb-3">
                                    <label className="custom-file-label" htmlFor="pdf-file">Choose PDF file</label>
                                    <input type="file" className="custom-file-input" id="pdf-file" name="pdf_file" accept="application/pdf" onChange={handleFileChange}/>
                                </div>
                                <button type="submit" className="main-btn" id="convert-button">Upload & Convert</button>
                            </form>
                        </div>
                    </div>
                    <div className="col-lg-12 col-md-10 col-12 text-center mt-4">
                        <div className="pdfto-word-result">
                            {progress > 0 && (
                                <div className="mt-4" id="progress-bar">
                                    <div className="progress">
                                        <div className="progress-bar progress-bar-striped progress-bar-animated"
                                            role="progressbar"
                                            style={{ width: `${progress}%` }}
                                            aria-valuenow={progress}
                                            aria-valuemin="0"
                                            aria-valuemax="100">
                                        </div>
                                    </div>
                                    <div className="mt-2 convert" id="progress-text">
                                        {progress === 100 ? "Conversion Complete!" : `Converting... ${progress}%`}
                                    </div>
                                </div>
                            )}

                            {convertedFileUrl &&  (
                                <div className="mt-4">
                                  {progress === 100 ?
                                    <a href={convertedFileUrl} className="main-btn" download>
                                        Download Word File
                                    </a> : ""}
                                

                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};
