import React, { useEffect, useState } from "react";
import { db } from "../../firebase/firebase";
import { doc, getDoc, collection, query, where, getDocs } from "firebase/firestore";
import { useParams } from "react-router-dom";
import { Container, Typography, CircularProgress, Card, CardContent, Avatar, Box, Chip } from "@mui/material";
import PageMetadata from "../pageMetaData";

const BlogDetail = () => {
  const { slug } = useParams();
  const [blog, setBlog] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchBlog = async () => {
      try {
        const q = query(collection(db, "blogs"), where("slug", "==", slug));
        const querySnapshot = await getDocs(q);
        if (!querySnapshot.empty) {
          const doc = querySnapshot.docs[0];
          setBlog({ id: doc.id, ...doc.data() });
        }
      } catch (error) {
        console.error("Error fetching blog:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchBlog();
  }, [slug]);

  if (loading) {
    return <Box style={{height:"500px"}}><CircularProgress sx={{ display: "block", margin: "auto", mt: 4 }} /></Box> ;
  }

  if (!blog) {
    return (
      <Typography variant="h5" align="center" mt={4} color="error">
        Blog not found.
      </Typography>
    );
  }

  return (
    <Container maxWidth="md" sx={{ mt: 4, mb:3 }}>
<PageMetadata
title={blog.title}
description={blog.description}
keywords={blog.keywords.map((keyword, index) => (
    <p>{keyword}, </p>
  ))}
/>

      <Card sx={{ p: 3, boxShadow: 3, borderRadius: 3 }}>
        {blog.thumbnail && (
          <img
            src={blog.thumbnail}
            alt={blog.title}
            style={{ width: "100%", height: "auto", borderRadius: "10px", marginBottom: "20px" }}
          />
        )}
        {/* Author Details */}
        <Box display="flex" alignItems="center" gap={2} mb={2}>
          <Avatar src={blog.authorImage} alt={blog.authorname} sx={{ width: 50, height: 50 }} />
          <Typography variant="h6" color="text.primary">
            {blog.authorname}
          </Typography>
        </Box>

        {/* Blog Content */}
        <Typography variant="h4" fontWeight="bold" gutterBottom>
          {blog.title}
        </Typography>
        <Typography variant="body1" color="text.secondary" paragraph>
          {blog.description}
        </Typography>
        <Typography variant="body1" component="div" sx={{ mt: 2 }} dangerouslySetInnerHTML={{ __html: blog.content }} />

        {/* Keywords Section */}
        <Box mt={3}>
          <Typography variant="subtitle1" fontWeight="bold">
            Keywords:
          </Typography>
          <Box mt={1} display="flex" flexWrap="wrap" gap={1}>
            {blog.keywords.map((keyword, index) => (
              <Chip key={index} label={keyword} color="primary" variant="outlined" />
            ))}
          </Box>
        </Box>
      </Card>
    </Container>
  );
};

export default BlogDetail;
