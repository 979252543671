import React, { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Tesseract from 'tesseract.js';
import {
  Container,
  Typography,
  Box,
  Button,
  Paper,
  CircularProgress,
  TextField,
} from '@mui/material';

const ImageToText = () => {
  const [file, setFile] = useState(null);
  const [text, setText] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleExtractText = async () => {
    if (!file) {
      toast.error('Please select an image.');
      return;
    }

    setIsLoading(true);

    try {
      // Use Tesseract.js to extract text from the image
      const { data: { text: extractedText } } = await Tesseract.recognize(
        file,
        'eng',
        {
          logger: (m) => console.log(m),
        }
      );

      setText(extractedText);
      toast.success('Text extracted successfully!');
    } catch (error) {
      console.error('Error extracting text:', error);
      toast.error('Failed to extract text.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container maxWidth="md">
      <Box sx={{ mt: 4, textAlign: 'center' }}>
        <Typography variant="h3" gutterBottom>
          Image to Text Converter
        </Typography>
        <Paper elevation={3} sx={{ p: 4, mt: 2 }}>
          <input
            type="file"
            accept="image/*"
            onChange={handleFileChange}
            style={{ display: 'none' }}
            id="upload-button"
          />
          <label htmlFor="upload-button">
            <Button variant="contained" component="span">
              Upload Image
            </Button>
          </label>
          {file && (
            <Box sx={{ mt: 2 }}>
              <Typography variant="body1">
                Selected file: {file.name}
              </Typography>
            </Box>
          )}
          <Button
            variant="contained"
            color="primary"
            onClick={handleExtractText}
            disabled={isLoading}
            fullWidth
            sx={{ mt: 2 }}
          >
            {isLoading ? <CircularProgress size={24} /> : 'Extract Text'}
          </Button>
          {text && (
            <Box sx={{ mt: 2 }}>
              <Typography variant="h6" gutterBottom>
                Extracted Text:
              </Typography>
              <TextField
                value={text}
                fullWidth
                multiline
                rows={10}
                variant="outlined"
                sx={{ mt: 1 }}
              />
            </Box>
          )}
        </Paper>
      </Box>
      <ToastContainer />
    </Container>
  );
};

export default ImageToText;