import PasswordGenerator from "../components/passwordGenerator/passwordGenerator"
import PageMetadata from "../components/pageMetaData"
import { SideBar } from "../components/sidebar"
export const PasswordGeneratorPage=()=>{

    return(<>
     <PageMetadata   title="Image Resize for Free - Freesolutions.in"
        description="description" content="Resize image with advance options. Freesolutions.in website which provides free online tools for images and pdf"
        keywords="Image Resize, freesolutions, online tools" />
        <SideBar/>
    <PasswordGenerator/>

    </>)

}