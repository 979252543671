import React, { useState } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  Container,
  Typography,
  Box,
  TextField,
  Button,
  CircularProgress,
  Paper,
} from '@mui/material';

const SitemapGenerator = () => {
  const [websiteUrl, setWebsiteUrl] = useState('');
  const [sitemapContent, setSitemapContent] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleGenerate = async () => {
    if (!websiteUrl) {
      toast.error('Please enter a website URL.');
      return;
    }

    setIsLoading(true);

    try {
      const response = await axios.post('https://separately-crisp-titmouse.ngrok-free.app/api/sitemap/generate', {
        website_url: websiteUrl,
      });

      setSitemapContent(response.data.sitemap_content);
      toast.success('Sitemap generated successfully!');
    } catch (error) {
      console.error('Error generating sitemap:', error);
      toast.error('Failed to generate sitemap.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleDownload = async () => {
    if (!sitemapContent) {
      toast.error('No sitemap content to download.');
      return;
    }

    try {
      const response = await axios.post(
        'https://separately-crisp-titmouse.ngrok-free.app/api/sitemap/download',
        { sitemap_content: sitemapContent },
        { responseType: 'blob' }
      );

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'sitemap.xml');
      document.body.appendChild(link);
      link.click();
      link.remove();
      toast.success('Sitemap downloaded successfully!');
    } catch (error) {
      console.error('Error downloading sitemap:', error);
      toast.error('Failed to download sitemap.');
    }
  };

  return (
    <Container maxWidth="md">
      <Box sx={{ mt: 4, textAlign: 'center' }}>
        <Typography variant="h3" gutterBottom>
          Sitemap Generator
        </Typography>
        <Paper elevation={3} sx={{ p: 4, mt: 2 }}>
          <TextField
            label="Website URL"
            fullWidth
            value={websiteUrl}
            onChange={(e) => setWebsiteUrl(e.target.value)}
            placeholder="https://example.com"
            sx={{ mb: 2 }}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleGenerate}
            disabled={isLoading}
            fullWidth
            sx={{ mb: 2 }}
          >
            {isLoading ? <CircularProgress size={24} /> : 'Generate Sitemap'}
          </Button>
          {sitemapContent && (
            <Button
              variant="contained"
              color="success"
              onClick={handleDownload}
              fullWidth
            >
              Download Sitemap
            </Button>
          )}
        </Paper>
      </Box>
      <ToastContainer />
    </Container>
  );
};

export default SitemapGenerator;