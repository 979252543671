import React, { useEffect, useState } from "react";
import { db } from "../firebase/firebase";
import { collection, query, where, getDocs, doc, updateDoc } from "firebase/firestore";
import { Container, Typography, Card, CardContent, CardActions, Button, Grid } from "@mui/material";
import { CheckCircle, Cancel } from "@mui/icons-material";
import { useUser } from "../context/userContext";
const AdminDashboard = () => {
  const [pendingBlogs, setPendingBlogs] = useState([]);
  const user = useUser()


  useEffect(() => {
  
    const fetchPendingBlogs = async () => {
      const q = query(collection(db, "blogs"), where("status", "==", "pending"));
      const querySnapshot = await getDocs(q);
      const blogs = [];
      querySnapshot.forEach((doc) => {
        blogs.push({ id: doc.id, ...doc.data() });
      });
      setPendingBlogs(blogs);
    };
    fetchPendingBlogs();
  }, []);

  const handleApprove = async (blogId) => {
    const blogRef = doc(db, "blogs", blogId);
    await updateDoc(blogRef, { status: "approved" });
    setPendingBlogs(pendingBlogs.filter((blog) => blog.id !== blogId));
  };

  const handleReject = async (blogId) => {
    const blogRef = doc(db, "blogs", blogId);
    await updateDoc(blogRef, { status: "rejected" });
    setPendingBlogs(pendingBlogs.filter((blog) => blog.id !== blogId));
  };

  if(user.isAdmin !== true){
    return(<p>UnAuthorised</p>)
  }

  return (
    <Container>
      <Typography variant="h4" gutterBottom align="center">
        Pending Blogs
      </Typography>
      <Grid container spacing={3}>
        {pendingBlogs.map((blog) => (
          <Grid item xs={12} sm={6} md={4} key={blog.id}>
            <Card elevation={3}>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  {blog.title}
                </Typography>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  dangerouslySetInnerHTML={{ __html: blog.content }}
                />
              </CardContent>
              <CardActions>
                <Button
                  variant="contained"
                  color="success"
                  startIcon={<CheckCircle />}
                  onClick={() => handleApprove(blog.id)}
                >
                  Approve
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  startIcon={<Cancel />}
                  onClick={() => handleReject(blog.id)}
                >
                  Reject
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default AdminDashboard;
