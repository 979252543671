import React, { useState } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  Container,
  Typography,
  Box,
  TextField,
  Button,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';

const IframeGenerator = () => {
  const [url, setUrl] = useState('');
  const [title, setTitle] = useState('iFrame');
  const [width, setWidth] = useState('100%');
  const [height, setHeight] = useState('600px');
  const [scrollbar, setScrollbar] = useState('no');
  const [border, setBorder] = useState('no');
  const [borderType, setBorderType] = useState('1px');
  const [borderColor, setBorderColor] = useState('#FFFFFF');
  const [allowFullscreen, setAllowFullscreen] = useState('yes');
  const [iframeCode, setIframeCode] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleGenerate = async () => {
    if (!url) {
      toast.error('Please enter a URL.');
      return;
    }

    setIsLoading(true);

    try {
      const response = await axios.post('https://separately-crisp-titmouse.ngrok-free.app/api/iframe/generate', {
        url: url,
        title: title,
        width: width,
        height: height,
        scrollbar: scrollbar,
        border: border,
        border_type: borderType,
        border_color: borderColor,
        allow_fullscreen: allowFullscreen,
      });

      setIframeCode(response.data.iframe_code);
      toast.success('iFrame code generated successfully!');
    } catch (error) {
      console.error('Error generating iFrame code:', error);
      toast.error('Failed to generate iFrame code.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container maxWidth="md">
      <Box sx={{ mt: 4, textAlign: 'center' }}>
        <Typography variant="h3" gutterBottom>
          Advanced iFrame Generator
        </Typography>
        <Paper elevation={3} sx={{ p: 4, mt: 2 }}>
          <TextField
            label="URL"
            fullWidth
            value={url}
            onChange={(e) => setUrl(e.target.value)}
            placeholder="https://example.com"
            sx={{ mb: 2 }}
          />
          <TextField
            label="Title"
            fullWidth
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            sx={{ mb: 2 }}
          />
          <TextField
            label="Width"
            fullWidth
            value={width}
            onChange={(e) => setWidth(e.target.value)}
            sx={{ mb: 2 }}
          />
          <TextField
            label="Height"
            fullWidth
            value={height}
            onChange={(e) => setHeight(e.target.value)}
            sx={{ mb: 2 }}
          />
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel>Scrollbar</InputLabel>
            <Select
              value={scrollbar}
              onChange={(e) => setScrollbar(e.target.value)}
              label="Scrollbar"
            >
              <MenuItem value="no">No</MenuItem>
              <MenuItem value="yes">Yes</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel>Border</InputLabel>
            <Select
              value={border}
              onChange={(e) => setBorder(e.target.value)}
              label="Border"
            >
              <MenuItem value="no">No</MenuItem>
              <MenuItem value="yes">Yes</MenuItem>
            </Select>
          </FormControl>
          {border === 'yes' && (
            <>
              <TextField
                label="Border Type"
                fullWidth
                value={borderType}
                onChange={(e) => setBorderType(e.target.value)}
                sx={{ mb: 2 }}
              />
              <TextField
                label="Border Color"
                fullWidth
                value={borderColor}
                onChange={(e) => setBorderColor(e.target.value)}
                sx={{ mb: 2 }}
              />
            </>
          )}
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel>Allow Fullscreen</InputLabel>
            <Select
              value={allowFullscreen}
              onChange={(e) => setAllowFullscreen(e.target.value)}
              label="Allow Fullscreen"
            >
              <MenuItem value="yes">Yes</MenuItem>
              <MenuItem value="no">No</MenuItem>
            </Select>
          </FormControl>
          <Button
            variant="contained"
            color="primary"
            onClick={handleGenerate}
            disabled={isLoading}
            fullWidth
          >
            {isLoading ? 'Generating...' : 'Generate iFrame Code'}
          </Button>
          {iframeCode && (
            <Box sx={{ mt: 2 }}>
              <Typography variant="h6" gutterBottom>
                iFrame Code:
              </Typography>
              <TextField
                value={iframeCode}
                fullWidth
                multiline
                rows={4}
                variant="outlined"
                sx={{ mt: 1 }}
              />
            </Box>
          )}
        </Paper>
      </Box>
      <ToastContainer />
    </Container>
  );
};

export default IframeGenerator;