import ContactUs from "../../components/pageComponents/contactUs"
import { SideBar } from "../../components/sidebar"
import PageMetadata from "../../components/pageMetaData"
export const ConstactUsPagePage =()=>{


return (<>
<PageMetadata   title="ContactUs - Freesolutions.in"
        description="description" content="Contact Us. Freesolutions.in website which provides free online tools for images and pdf"
        keywords="ContactUs,freesolutions,online free tools" />
<SideBar/>
<ContactUs/>
</>)

}