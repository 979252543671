import React from 'react';
// Optional: For styling
import TermsAndConditions from '../../components/pageComponents/termsandConditions';
import { SideBar } from '../../components/sidebar';
import PageMetadata from '../../components/pageMetaData';
const TermsAndConditionsPage = () => {
  return (
    <>
    <PageMetadata   title="Privacy Policy - Freesolutions.in"
        description="description" content="Privacy Policy for freesolutions.in website which provides free online tools for images and pdf"
        keywords="privacy policy,freesolutions,online tools" />
    <SideBar/>
    <TermsAndConditions/>
    </>
    
  );
};

export default TermsAndConditionsPage;